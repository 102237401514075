import React, { type FC } from 'react';

import { ReactParent } from 'types';

import TimeOffPieChart from '../../TimeOffPieChart';

import { Styled } from './TimeOffStatisticPieChart.styled';

type TimeOffStatisticPieChartProps = ReactParent & {
	name: string;
	offsetPercentage?: number;
	color: string;
	daysUsed: number;
	totalDays: number | null;
	emoji: string;
};

const TimeOffStatisticPieChart: FC<TimeOffStatisticPieChartProps> = ({
	name,
	offsetPercentage = 0,
	color,
	daysUsed,
	totalDays,
	emoji,
}) => {
	const percentUsed = totalDays ? Math.round((daysUsed / totalDays) * 100) : 0;
	const statisticsPercentText = totalDays ? `${percentUsed}%` : `${daysUsed} d`;
	const statisticPercentDaysAspectText = totalDays
		? `${daysUsed}/${totalDays} Days`
		: totalDays !== null
			? `from ${totalDays}`
			: '';

	return (
		<Styled.Root>
			<Styled.PieWrap marginRight={totalDays ? '24px' : '16px'}>
				<TimeOffPieChart
					offsetPercentage={offsetPercentage}
					color={color}
					percentUsed={percentUsed}
					emoji={emoji}
				/>
			</Styled.PieWrap>
			<Styled.StatisticName>{name}:</Styled.StatisticName>
			<Styled.StatisticDataWrap alignSelf={totalDays === null ? 'center' : 'end'}>
				<Styled.StatisticPercent>{statisticsPercentText}</Styled.StatisticPercent>
				<Styled.StatisticPercentDaysAspect>
					{statisticPercentDaysAspectText}
				</Styled.StatisticPercentDaysAspect>
			</Styled.StatisticDataWrap>
		</Styled.Root>
	);
};

export default TimeOffStatisticPieChart;
