import React, { useCallback, useEffect, useRef, useState, type FC } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import useMedia from 'use-media';

import { atsDucks } from 'modules/ATS/ducks';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { hrDucks } from 'modules/HR/ducks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

import EmployeeView from './EmployeeView/EmployeeView';
import { getCalendarDates } from './HRDashboard.helpers';
import { Styled } from './HRDashboard.styled';
import { HRDashboardProps, RequestTabType } from './HRDashboard.types';
import HRDashboardMobileComponents from './HRDashboardMobileComponents/HRDashboardMobileComponents';
import HRView from './HRView/HRView';

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const HRDashboard: FC<HRDashboardProps> = ({
	isSuperUser,
	employeeId,
	brandingData,
	employeeList,
	isRequestModalOpen,
	pendingTimeOffRequests,
	employeeTimeOffList,
	employeeTimeOffStatics,
	user,
	timeOffStaticsCompanyWide,
	loading,
	handleOpenRequestModal,
	handleCloseRequestModal,
	getPendingTimeOffRequests,
	getEmployeeList,
	approveAllTimeOffRequestsRequested,
	declineTimeOffRequestRequested,
	approveTimeOffRequestRequested,
	getAllTimeOffRequestsRequested,
	getTimeOffRequestsEmployeeRequested,
	getTimeOffStaticsPerEmployeeRequested,
	getTimeOffStaticsCompanyRequested,
}) => {
	const [selectedTab, setSelectedTab] = useState<RequestTabType>('requests');
	const [currentCalendarDate, setCurrentCalendarDate] = useState<Date>(moment().toDate());
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);
	const employeeInitialized = useRef(false);
	const superUserInitialized = useRef(false);

	useEffect(() => {
		if (!employeeInitialized.current && !isSuperUser && user?.employee) {
			getTimeOffRequestsEmployeeRequested(user.employee);
			getTimeOffStaticsPerEmployeeRequested({
				employeeId: user.employee,
				onlyApproved: true,
			});
			employeeInitialized.current = true;
		}
	}, [user?.employee, isSuperUser]);

	useEffect(() => {
		if (!superUserInitialized.current && isSuperUser) {
			employeeList.length === 0 && getEmployeeList();
			getPendingTimeOffRequests();
			getTimeOffStaticsCompanyRequested(true);
			superUserInitialized.current = true;
		}
	}, [isSuperUser]);

	const handleUpdateCalendarDate = (date: Date) => {
		setCurrentCalendarDate(date);
	};

	const refreshCalendarAndRequests = useCallback(() => {
		const { fromDate, toDate } = getCalendarDates(currentCalendarDate);
		getAllTimeOffRequestsRequested({ startDate: fromDate, endDate: toDate });
		isSuperUser && getPendingTimeOffRequests();
	}, [currentCalendarDate]);

	const handleMobileTabChange = useCallback((tab: RequestTabType) => {
		setSelectedTab(tab);
	}, []);

	const handleApproveTimeOffRequest = (requestId: number) => {
		approveTimeOffRequestRequested(requestId, () => refreshCalendarAndRequests());
	};

	const handleDeclineTimeOffRequest = (
		values: { requestId: number; comment?: string },
		cb?: () => void,
	) => {
		declineTimeOffRequestRequested(values, () => {
			cb && cb();
			refreshCalendarAndRequests();
		});
	};

	const handleApproveAllRequests = () => {
		approveAllTimeOffRequestsRequested(() => refreshCalendarAndRequests());
	};

	const logoUrl = brandingData?.logo?.split('/').includes('images')
		? brandingData?.logo
		: brandingData?.logo && `${baseUrl}${brandingData?.logo}`;

	const showRequestTab = !isMobile || selectedTab === 'requests';
	const showCalendarTab = !isMobile || selectedTab === 'calendar';
	const numberOfRequests = isSuperUser ? pendingTimeOffRequests.length : employeeTimeOffList.length;

	return (
		<Styled.Root>
			{isMobile && (
				<HRDashboardMobileComponents
					selectedTab={selectedTab}
					handleTabChange={handleMobileTabChange}
					employeeRequestsNumber={numberOfRequests}
					handleOpenModal={() => handleOpenRequestModal()}
				/>
			)}
			{!isMobile && (
				<Styled.SubHeader>
					<Styled.Logo>
						<img src={logoUrl} alt='Client Logo' />
					</Styled.Logo>
					<Styled.CompanyName textColor={brandingData.textColour}>
						{brandingData?.clientName}
					</Styled.CompanyName>
				</Styled.SubHeader>
			)}
			{isSuperUser ? (
				<HRView
					isRequestModalOpen={isRequestModalOpen}
					currentCalendarDate={currentCalendarDate}
					handleUpdateCalendarDate={handleUpdateCalendarDate}
					showRequestTab={showRequestTab}
					showCalendarTab={showCalendarTab}
					employeeData={employeeList}
					pendingTimeOffRequests={pendingTimeOffRequests}
					timeOffStaticsCompanyWide={timeOffStaticsCompanyWide}
					employeeId={employeeId}
					loading={loading}
					handleApproveAllRequests={handleApproveAllRequests}
					handleApproveTimeOffRequest={handleApproveTimeOffRequest}
					handleDeclineTimeOffRequest={handleDeclineTimeOffRequest}
					handleOpenRequestModal={handleOpenRequestModal}
					handleCloseRequestModal={handleCloseRequestModal}
				/>
			) : (
				<EmployeeView
					isRequestModalOpen={isRequestModalOpen}
					currentCalendarDate={currentCalendarDate}
					showRequestTab={showRequestTab}
					showCalendarTab={showCalendarTab}
					employeeRequests={employeeTimeOffList}
					employeeTimeOffStatics={employeeTimeOffStatics}
					employeeId={employeeId}
					loading={loading}
					handleOpenRequestModal={handleOpenRequestModal}
					handleCloseRequestModal={handleCloseRequestModal}
					handleUpdateCalendarDate={handleUpdateCalendarDate}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		brandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
		employeeList: hrDucks.hrSelectors.getEmployeeShortList(state),
		pendingTimeOffRequests: hrDucks.hrSelectors.getPendingTimeOffRequests(state),
		employeeTimeOffList: hrDucks.hrSelectors.getCurrentEmployeeTimeOffRequests(state),
		employeeTimeOffStatics: hrDucks.hrSelectors.getTimeOffStaticsPerEmployee(state),
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		timeOffStaticsCompanyWide: hrDucks.hrSelectors.getTimeOffStaticsCompanyWide(state),
	}),
	{
		getEmployeeList: hrDucks.hrActions.getEmployeeShortListRequested,
		getPendingTimeOffRequests: hrDucks.hrActions.getTimeOffRequestsPendingRequested,
		approveAllTimeOffRequestsRequested: hrDucks.hrActions.approveAllTimeOffRequestsRequested,
		declineTimeOffRequestRequested: hrDucks.hrActions.declineTimeOffRequestRequested,
		approveTimeOffRequestRequested: hrDucks.hrActions.approveTimeOffRequestRequested,
		getAllTimeOffRequestsRequested: hrDucks.hrActions.getAllTimeOffRequestsRequested,
		getTimeOffRequestsEmployeeRequested:
			hrDucks.hrActions.getTimeOffRequestsForEmployeeByIdRequested,
		getTimeOffStaticsPerEmployeeRequested: hrDucks.hrActions.getTimeOffStaticsPerEmployeeRequested,
		getTimeOffStaticsCompanyRequested: hrDucks.hrActions.getTimeOffStaticsCompanyRequested,
	},
)(HRDashboard);
