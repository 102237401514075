import PhoneInput from 'react-phone-number-input';

import { Modal as ModalAntd } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Modal = styled(ModalAntd)`
		.ant-modal-content {
			padding: 32px 40px;
		}
	`;
	export const ModalHead = styled.div`
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
		font-size: 16px;
	`;

	export const ModalTitle = styled.div`
		display: flex;
		align-items: center;
		button {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	`;

	export const UserHint = styled.span`
		font-size: 12px;
		color: ${COLORS.darkGray4};
	`;

	export const ModalContent = styled.div`
		width: 440px;
		max-width: 100%;
	`;

	export const FieldsBlock = styled.div`
		margin-bottom: 25px;
		label {
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
			color: ${COLORS.black};
		}
		span {
			top: 0;
			font-weight: 400;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		flex-direction: row-reverse;
		margin-top: 26px;
		gap: 16px;
	`;

	export const PhoneField = styled(PhoneInput)`
		.PhoneInputInput {
			border-radius: 4px;
			border: 1px solid ${COLORS.gray};
			padding: 2px 10px;
		}
	`;
}
