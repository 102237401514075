import React, { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import Spinner from 'components/Spinner';
import { TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { Routes } from 'modules/HR/routes/types';
import { GenericType } from 'types';

import TimeOffStatisticLine from './TimeOffStatisticLine';
import TimeOffStatisticPieChart from './TimeOffStatisticPieChart';
import { Styled } from './TimeOffUsedStatistics.styled';
import { TimeOffs, TimeOffTypes } from './TimeOffUsedStatistics.types';

type TimeOffUsedStatisticsProps = {
	statisticData: TimeOffStaticsType[];
	dashboardChart: boolean;
	employeeId?: number;
	loading: GenericType;
};

const WORKING_DAYS_IN_YEAR = 260;

const TimeOffUsedStatistics: FC<TimeOffUsedStatisticsProps> = ({
	statisticData,
	dashboardChart,
	employeeId,
	loading,
}) => {
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);
	const navigate = useNavigate();

	const getStaticsDataForType = (statistic: TimeOffStaticsType[], type: TimeOffTypes) => {
		return statistic?.find((stat) => stat.timeOffType === type);
	};

	const vacationStatics = getStaticsDataForType(statisticData, TimeOffTypes.VACATION);
	const sickLeaveStatics = getStaticsDataForType(statisticData, TimeOffTypes.ILLNESS);
	const parentalStatics = getStaticsDataForType(statisticData, TimeOffTypes.PARENTAL);
	const otherStatics = getStaticsDataForType(statisticData, TimeOffTypes.OTHER);

	const title = dashboardChart ? 'Time Offs Used this year' : 'Time Off';
	const secondaryTitle = dashboardChart ? '% from total Time offs' : 'Taken days';

	const isLoading = dashboardChart
		? loading?.getTimeOffStaticsCompanyLoad
		: loading?.getTimeOffStaticsPerEmployeeLoad;

	const handleViewPersonalStatistics = () => {
		navigate(`${Routes.HRModule}${Routes.Employee}/${employeeId}`);
	};

	return (
		<Styled.Root isDashboardChart={dashboardChart} isMobile={isMobile}>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<Styled.Title>{title}</Styled.Title>
					{dashboardChart && (
						<TimeOffStatisticLine
							name={'Vacation'}
							color={TimeOffs.VACATION.color}
							daysUsed={vacationStatics?.usedDays || 0}
							totalDays={vacationStatics?.totalDays || 0}
							emoji={TimeOffs.VACATION.emoji}
						/>
					)}
					<Styled.TitleContainer>
						<Styled.SecondaryTitle>{secondaryTitle}</Styled.SecondaryTitle>
						{dashboardChart && !isMobile ? (
							<Styled.LinkToPersonalStatistics onClick={handleViewPersonalStatistics}>
								View personal
							</Styled.LinkToPersonalStatistics>
						) : null}
					</Styled.TitleContainer>
					<Styled.StatisticsContainer direction={dashboardChart ? 'column' : 'row'}>
						<TimeOffStatisticPieChart
							name={'Vacation'}
							color={TimeOffs.VACATION.color}
							daysUsed={vacationStatics?.usedDays || 0}
							totalDays={dashboardChart ? WORKING_DAYS_IN_YEAR : vacationStatics?.totalDays || 0}
							emoji={TimeOffs.VACATION.emoji}
						/>
						<TimeOffStatisticPieChart
							name={'Sick Day'}
							color={TimeOffs.ILLNESS.color}
							daysUsed={sickLeaveStatics?.usedDays || 0}
							totalDays={dashboardChart ? WORKING_DAYS_IN_YEAR : null}
							emoji={TimeOffs.ILLNESS.emoji}
						/>
						<TimeOffStatisticPieChart
							name={'Parental leave'}
							// offsetPercentage={parentalOffsetPercentage}
							color={TimeOffs.PARENTAL.color}
							daysUsed={parentalStatics?.usedDays || 0}
							totalDays={dashboardChart ? WORKING_DAYS_IN_YEAR : null}
							emoji={TimeOffs.PARENTAL.emoji}
						/>
						<TimeOffStatisticPieChart
							name={'Other'}
							// offsetPercentage={otherOffsetPercentage}
							color={TimeOffs.OTHER.color}
							daysUsed={otherStatics?.usedDays || 0}
							totalDays={dashboardChart ? WORKING_DAYS_IN_YEAR : null}
							emoji={TimeOffs.OTHER.emoji}
						/>
					</Styled.StatisticsContainer>
				</>
			)}
		</Styled.Root>
	);
};

export default TimeOffUsedStatistics;
