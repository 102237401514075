import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type PieWrapProps = {
	marginRight: string;
};

type StatisticDataWrapProps = {
	alignSelf: 'center' | 'end';
};

export namespace Styled {
	export const Root = styled.div`
		background-color: ${COLORS.lightGray3};
		border-radius: 6px;
		padding: 12px;
		display: flex;
		align-items: center;
		min-width: 260px;
	`;

	export const PieWrap = styled.div<PieWrapProps>`
		margin-right: ${(props) => props.marginRight || '24px'};
	`;

	export const StatisticName = styled.div`
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.22px;
		color: ${COLORS.darkGray3};
		margin-right: 16px;
	`;

	export const StatisticDataWrap = styled.div<StatisticDataWrapProps>`
		margin-left: auto;
		align-self: ${(props) => props.alignSelf || 'end'};
	`;

	export const StatisticPercent = styled.div`
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		color: ${COLORS.darkGray3};
		text-align: right;
	`;

	export const StatisticPercentDaysAspect = styled.div`
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		color: ${COLORS.darkGray2};
	`;
}
