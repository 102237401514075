import React, { useCallback, useEffect, useState } from 'react';
import { View, Views } from 'react-big-calendar';
import { connect } from 'react-redux';

import moment from 'moment';

import { ButtonTypes } from 'components/Button/Button.types';
import { useMount } from 'hooks';
import { IUser } from 'modules/Common/types';
import { RequestTimeOffType, TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import HRRequestEditTimeOffModal from 'modules/HR/components/HRRequestEditTimeOffModal';
import {
	CreateTimeOffRequestType,
	GetTimeOffRequestDurationType,
	TimeOffRequestDurationType,
	UpdateTimeOffRequestType,
} from 'modules/HR/components/HRRequestEditTimeOffModal/HRRequestEditTimeOffModal.types';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import HRCalendar from 'modules/HR/containers/HRCalendar';
import {
	getStartEndDate,
	validateTypeView,
} from 'modules/HR/containers/HRCalendar/HRCalendar.helpers';
import { hrDucks } from 'modules/HR/ducks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, GenericType, UserRolesEnum } from 'types';
import { getCountryDateFormate, getYearsBetweenDates } from 'utils/helpers';

import { Styled } from './HRCalendarPage.styled';

type HRCalendarPageProps = {
	userInfo: IUser;
	countries: CountriesType;
	timeOffRequestDuration: TimeOffRequestDurationType;
	timeOffStaticsPerEmployee: TimeOffStaticsType[];
	employeeOptions: { value: number; label: string }[];
	loading: GenericType;
	getAllTimeOffRequestsRequested: ({
		startDate,
		endDate,
	}: {
		startDate: string;
		endDate: string;
	}) => void;
	getTimeOffRequestDurationRequested: (values: GetTimeOffRequestDurationType) => void;
	createTimeOffRequestRequested: (payload: CreateTimeOffRequestType, cb?: () => void) => void;
	getTimeOffStaticsPerEmployeeRequested: ({
		employeeId,
		onlyApproved,
	}: {
		employeeId: number;
		onlyApproved?: boolean;
	}) => void;
	getTimeOffStaticsPerEmployeeYearsRequested: ({
		employeeId,
		onlyApproved,
		years,
	}: {
		employeeId: number;
		onlyApproved?: boolean;
		years?: number[];
	}) => void;
	updateTimeOffRequestRequested: (data: UpdateTimeOffRequestType, cb?: () => void) => void;
	getEmployeeOptionsRequested: () => void;
};

const HRCalendarPage = ({
	userInfo,
	countries,
	timeOffStaticsPerEmployee,
	timeOffRequestDuration,
	employeeOptions,
	loading,
	getAllTimeOffRequestsRequested,
	getTimeOffRequestDurationRequested,
	createTimeOffRequestRequested,
	getTimeOffStaticsPerEmployeeRequested,
	getTimeOffStaticsPerEmployeeYearsRequested,
	updateTimeOffRequestRequested,
	getEmployeeOptionsRequested,
}: HRCalendarPageProps) => {
	const [isModalOpen, setIsModalOpen] = useState<number | null>(null);
	const [currentEvent, setCurrentEvent] = useState<RequestTimeOffType | null>(null);
	const [currentDate, setDate] = useState<Date>(moment().toDate());
	const [currentView, setCurrentView] = useState<View>(Views.MONTH);
	const { roleId } = userInfo || {};
	const isSuperUser = [UserRolesEnum.HR_MANAGER, UserRolesEnum.JAT_SUPER_USER].includes(roleId);
	const employeeId = userInfo?.employee;
	const dateFormat = getCountryDateFormate(countries.isUK);

	useMount(() => {
		employeeId && getTimeOffStaticsPerEmployeeRequested({ employeeId });
		getEmployeeOptionsRequested();
	});

	const handleOpenModal = () => {
		employeeId && setIsModalOpen(employeeId);
	};

	const handleClosedModal = () => {
		setIsModalOpen(null);
		setCurrentEvent(null);
	};

	const handleCalculateTimeOffDuration = (values: GetTimeOffRequestDurationType) => {
		getTimeOffRequestDurationRequested(values);
	};

	const handleCreateTimeOffRequest = (values: CreateTimeOffRequestType, cb?: () => void) => {
		createTimeOffRequestRequested(values, () => {
			refreshTimeOffList();
			cb && cb();
		});
	};

	const refreshTimeOffList = () => {
		const currentRange = getStartEndDate(currentDate, currentView);
		const { fromDate, toDate } = currentRange && validateTypeView(currentRange, currentView);
		if (fromDate && toDate) {
			getAllTimeOffRequestsRequested({ startDate: fromDate, endDate: toDate });
		}
	};

	const handleEditTimeOffRequest = (values: UpdateTimeOffRequestType, cb?: () => void) => {
		updateTimeOffRequestRequested(values, () => {
			refreshTimeOffList();
			cb && cb();
		});
	};

	const updateCurrentEvent = (values: RequestTimeOffType) => {
		setCurrentEvent(values);
	};

	const updateCurrentDate = (date: Date) => {
		setDate(date);
	};

	const handleChangeView = useCallback((view: View) => {
		setCurrentView(view);
	}, []);

	const handleEmployeeDateRangeChange = useCallback((id: number, dates: [Date, Date]) => {
		const yearsToFetch = getYearsBetweenDates(dates);

		if (yearsToFetch.length > 0) {
			getTimeOffStaticsPerEmployeeYearsRequested({ employeeId: id, years: yearsToFetch });
		} else {
			getTimeOffStaticsPerEmployeeRequested({ employeeId: id });
		}
	}, []);

	useEffect(() => {
		if (currentEvent) {
			setIsModalOpen(currentEvent.id);
		}
	}, [currentEvent]);

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Calendar</Styled.Title>
				<HRThemedButton buttonType={ButtonTypes.primary} type='button' onClick={handleOpenModal}>
					Request Time Off
				</HRThemedButton>
			</Styled.Head>
			<Styled.Content>
				<HRCalendar
					handleEditTimeOffEvent={updateCurrentEvent}
					currentDate={currentDate}
					currentView={currentView}
					handleUpdateCurrentDate={updateCurrentDate}
					handleChangeView={handleChangeView}
				/>
			</Styled.Content>
			<HRRequestEditTimeOffModal
				isSuperUser={isSuperUser}
				employeeOptions={employeeOptions}
				employeeOrRequestId={isModalOpen}
				timeOffEventFormFields={currentEvent}
				onCancel={handleClosedModal}
				handleCreateTimeOffRequest={handleCreateTimeOffRequest}
				handleEditTimeOffEvent={handleEditTimeOffRequest}
				loading={loading}
				dateFormat={dateFormat}
				timeOffStaticsPerEmployee={timeOffStaticsPerEmployee}
				timeOffRequestDuration={timeOffRequestDuration}
				handleCalculateTimeOffDuration={handleCalculateTimeOffDuration}
				handleEmployeeDateRangeChange={handleEmployeeDateRangeChange}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
		userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		timeOffRequestDuration: hrDucks.hrSelectors.getTimeOffRequestDuration(state),
		timeOffStaticsPerEmployee: hrDucks.hrSelectors.getTimeOffStaticsPerEmployee(state),
		employeeOptions: hrDucks.hrSelectors.getEmployeeOptions(state),
	}),
	{
		getAllTimeOffRequestsRequested: hrDucks.hrActions.getAllTimeOffRequestsRequested,
		getTimeOffRequestDurationRequested: hrDucks.hrActions.getTimeOffRequestDurationRequested,
		createTimeOffRequestRequested: hrDucks.hrActions.createTimeOffRequestRequested,
		updateTimeOffRequestRequested: hrDucks.hrActions.updateTimeOffRequestRequested,
		getTimeOffStaticsPerEmployeeRequested: hrDucks.hrActions.getTimeOffStaticsPerEmployeeRequested,
		getTimeOffStaticsPerEmployeeYearsRequested:
			hrDucks.hrActions.getTimeOffStaticsPerEmployeeYearsRequested,
		getEmployeeOptionsRequested: hrDucks.hrActions.getEmployeeOptionsRequested,
	},
)(HRCalendarPage);
