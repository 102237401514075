import styled from 'styled-components/macro';

import { device, COLORS } from 'theme';

type ClientNameProps = {
	textColor?: string;
};

type InfoProps = {
	isClientContextDashboard?: boolean;
};

export namespace Styled {
	export const Root = styled.div`
		position: relative;
		padding: 24px 0 40px;
	`;

	export const DashboardLayout = styled.div``;

	export const LayoutColumns = styled.div<{ isMobile: boolean }>`
		display: flex;
		justify-content: space-between;
		gap: 24px;
		${({ isMobile }) =>
		isMobile &&
			`
				flex-direction: column;
				padding: 0 0 24px;
			`}
	`;

	export const LeftColumn = styled.div<{ isMobile: boolean }>`
		width: 66.8%;
		min-width: 380px;
		${({ isMobile }) =>
		isMobile &&
			`
				width: 100%;
				min-width: 100%;
			`}
		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const RightColumn = styled.div<{ isMobile: boolean }>`
		width: 32.15%;
		display: flex;
		flex-direction: column;
		gap: 24px;
		${({ isMobile }) =>
		isMobile &&
			`
				width: 100%;
			`}
	`;

	export const SubHeader = styled.div<InfoProps>`
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: -64px 0 64px;
		${({ isClientContextDashboard }) => `
			background-color: ${isClientContextDashboard ? `${COLORS.lightGray3}` : 'none'};
    	`}
	`;

	export const Logo = styled.div`
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 240px;
		height: 120px;
		max-height: 120px;
		padding: 8px;
		border-radius: 4px;
		display: flex;
		background-color: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow};
		z-index: 3;

		img {
			width: 100%;
			padding: 4px;
			object-fit: contain;
		}
	`;

	export const CompanyName = styled.div<ClientNameProps>`
		margin-top: 42px;
		margin-left: 300px;
		font-weight: 700;
		font-size: 32px;
		line-height: 40px;
		color: ${({ textColor }) => textColor};
	`;
}
