import React, { FC } from 'react';
import { useMedia } from 'react-use';

import dayjs from 'dayjs';

import { RequestTimeOffType, TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import TimeOffUsedStatisticsEmployee from 'modules/HR/components/TimeOffUsedStatisticsEmployee';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { GenericType } from 'types';

import HRCalendarWeekly from '../../HRCalendarWeekly';
import { Styled } from '../HRDashboard.styled';
import RequestsWidget from '../RequestsWidget/RequestsWidget';
interface EmployeeViewProps {
	isRequestModalOpen: number | null;
	currentCalendarDate: Date;
	showRequestTab: boolean;
	showCalendarTab: boolean;
	employeeRequests: RequestTimeOffType[];
	employeeTimeOffStatics: TimeOffStaticsType[];
	employeeId?: number;
	loading: GenericType;
	handleOpenRequestModal: (requestId?: number) => void;
	handleCloseRequestModal: () => void;
	handleUpdateCalendarDate: (date: Date) => void;
}

const EmployeeView: FC<EmployeeViewProps> = ({
	isRequestModalOpen,
	showRequestTab,
	showCalendarTab,
	currentCalendarDate,
	employeeRequests,
	employeeTimeOffStatics,
	employeeId,
	loading,
	handleOpenRequestModal,
	handleCloseRequestModal,
	handleUpdateCalendarDate,
}) => {
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);
	const upcomingVacationsRequests = employeeRequests.filter((request) =>
		dayjs(request.startDate).isAfter(dayjs()),
	);

	return (
		<Styled.DashboardLayout>
			<HRCalendarWeekly
				isRequestModalOpen={isRequestModalOpen}
				handleEditRequestModalOpen={handleOpenRequestModal}
				handleCloseRequestModal={handleCloseRequestModal}
				showCalendarTab={showCalendarTab}
				currentDate={currentCalendarDate}
				handleUpdateCurrentDate={handleUpdateCalendarDate}
			/>
			<Styled.LayoutColumns isMobile={isMobile}>
				<Styled.LeftColumn isMobile={isMobile}>
					{showRequestTab && (
						<RequestsWidget
							title='My Time Offs History'
							emptyRequestsTitle='No requests at the moment'
							employeeTimeOffStatics={employeeTimeOffStatics}
							isEmployeeView={true}
							employeeRequests={employeeRequests}
							employeeId={employeeId}
						/>
					)}
				</Styled.LeftColumn>
				<Styled.RightColumn isMobile={isMobile}>
					<TimeOffUsedStatisticsEmployee statisticData={employeeTimeOffStatics} loading={loading} />
					<RequestsWidget
						title='Upcoming Vacations'
						emptyRequestsTitle='No upcoming vacations at the moment'
						isEmployeeView={true}
						employeeRequests={upcomingVacationsRequests}
						shortView={true}
					/>
				</Styled.RightColumn>
			</Styled.LayoutColumns>
		</Styled.DashboardLayout>
	);
};

export default EmployeeView;
