import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import { Button, Tooltip, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import InfoIcon from 'components/SVG/InfoIcon';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { IUser } from 'modules/Common/types';
import { textValidator } from 'utils/validators';

import Attachments from '../../CreateEditEmployee/Attachments';

import { Styled } from './HRBoardingBlock.styled';

type HRBoardingBlockProps = {
	blockTitle: string;
	attachmentsList: UploadFile[];
	newAttachmentsList: Set<string>;
	blockHint?: string;
	handleUpdateAttachmentsList: (fileList: UploadFile[]) => void;
	handleUpdateForNewFiles: (newFiles: Set<string>) => void;
	user: IUser;
};

const HRBoardingBlock: FC<HRBoardingBlockProps> = ({
	blockTitle,
	attachmentsList,
	newAttachmentsList,
	handleUpdateAttachmentsList,
	handleUpdateForNewFiles,
	user,
	blockHint,
}) => {
	return (
		<Styled.Block>
			<Styled.BlockTitleWrap>
				<Styled.BlockTitle>{blockTitle}</Styled.BlockTitle>
				{blockHint && (
					<Tooltip title={blockHint} trigger={['hover']} placement='bottomLeft'>
						<Button type='ghost' size='small'>
							<InfoIcon width='20px' height='20px' />
						</Button>
					</Tooltip>
				)}
			</Styled.BlockTitleWrap>
			<FormBlockLine columns={1}>
				<Field name='description' validate={textValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='description'
							label='Description'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<TextArea
								{...input}
								placeholder={`Add ${blockTitle.toLocaleLowerCase()} details`}
								autoSize={{ minRows: 8, maxRows: 8 }}
							/>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
			<FormBlockLine columns={1}>
				<Attachments
					user={user}
					attachmentsList={attachmentsList}
					newAttachmentsList={newAttachmentsList}
					handleUpdateAttachmentsList={handleUpdateAttachmentsList}
					handleUpdateForNewFiles={handleUpdateForNewFiles}
				/>
			</FormBlockLine>
			<Field name='useAsDefault'>
				{({ input }) => <FormSwitch {...input} title='Use as Default Template' />}
			</Field>
		</Styled.Block>
	);
};

export default HRBoardingBlock;
