import dayjs from 'dayjs';

import {
	ContractStatusEnum,
	ContractStatusValuesEnum,
	IContractState,
	IInvoicesState,
	TagColoursType,
} from 'modules/Common/types';

export const StatusStylesData = {
	[ContractStatusEnum.FULFILLED]: {
		color: '#d46b08',
		background: '#fff7e6',
		borderColor: '#ffd591',
	},
	[ContractStatusEnum.OUTSTANDING]: {
		color: '#0958d9',
		background: '#e6f4ff',
		borderColor: '#91caff',
	},
	[ContractStatusEnum.ACCEPTED]: {
		color: '#389e0d',
		background: '#f6ffed',
		borderColor: '#b7eb8f',
	},
	[ContractStatusEnum.PENDING_APPROVAL]: {
		color: '#5c5c60',
		background: '#f5f5f5',
		borderColor: '#d9d9d9',
	},
};

export const ContractStatusColours = {
	[ContractStatusValuesEnum.Outstanding]: TagColoursType.Blue,
	[ContractStatusValuesEnum.Fulfilled]: TagColoursType.Orange,
	[ContractStatusValuesEnum.PendingForApproval]: TagColoursType.Volcano,
};

export const contractStatuses = [
	{
		id: 2,
		name: 'Accepted',
		value: 'ACCEPTED',
	},
	{
		id: 3,
		name: 'Fulfilled',
		value: 'FULFILLED',
	},
] as IContractState[];

export const invoiceStatuses = [
	{
		id: 1,
		name: 'Overdue',
		value: 'OVERDUE',
	},
	{
		id: 2,
		name: 'Outstanding',
		value: 'OUTSTANDING',
	},
	{
		id: 4,
		name: 'Paid',
		value: 'PAID_MANUALLY',
	},
] as IInvoicesState[];

export const invoicePaymentMethods = [
	{
		id: 1,
		name: 'Card',
		value: 'CARD',
	},
	{
		id: 3,
		name: 'BACS',
		value: 'BACS',
	},
];

export const statusOptionsAll = [
	{ label: 'Pending for approval', value: 'CONTRACT_PENDING_APPROVAL' },
	{ label: 'Outstanding (contract)', value: 'CONTRACT_OUTSTANDING' },
	{ label: 'Accepted', value: 'CONTRACT_ACCEPTED' },
	{ label: 'Fulfilled', value: 'CONTRACT_FULFILLED' },
	{ label: 'Outstanding (invoice)', value: 'INVOICE_OUTSTANDING' },
	{ label: 'Overdue', value: 'INVOICE_OVERDUE' },
	{ label: 'Paid', value: 'INVOICE_PAID' },
];

export const statusOptionsInvoices = [
	{ label: 'Outstanding', value: 'OUTSTANDING' },
	{ label: 'Overdue', value: 'OVERDUE' },
	{ label: 'Paid', value: 'PAID' },
];

export const statusOptionsContracts = [
	{ label: 'Outstanding', value: 'OUTSTANDING' },
	{ label: 'Accepted', value: 'ACCEPTED' },
	{ label: 'Fulfilled', value: 'FULFILLED' },
	{ label: 'Pending for approval', value: 'PENDING_APPROVAL' },
];

export const getDateRangeFromPeriod = (periodId: number) => {
	const today = dayjs();

	switch (periodId) {
	case 1: // This month
		return {
			fromDate: today.startOf('month').format('YYYY-MM-DD'),
			toDate: today.endOf('month').format('YYYY-MM-DD'),
		};
	case 2: // Last 3 months
		return {
			fromDate: today.subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
			toDate: today.endOf('month').format('YYYY-MM-DD'),
		};
	case 3: // This year
		return {
			fromDate: today.startOf('year').format('YYYY-MM-DD'),
			toDate: today.endOf('year').format('YYYY-MM-DD'),
		};
	default:
		return null;
	}
};

export const periodOptions = [
	{
		label: 'This month',
		value: 1,
	},
	{
		label: 'Last 3 months',
		value: 2,
	},
	{
		label: 'This year',
		value: 3,
	},
];
