import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { ButtonTypes } from 'components/Button';
import { RequestTimeOffType, TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import HRRequestComponent from 'modules/HR/components/HRRequestComponent';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import {
	CALENDAR_EVENT_TYPES,
	CalendarEmojisByTypes,
	MOBILE_VIEW_BREAKPOINT,
} from 'modules/HR/constants/HRModuleConstants.constants';
import { Routes } from 'modules/HR/routes/types';
import { GenericType } from 'types';

import NoRequests from '../NoRequests/NoRequests';

import { getNumberOfVacationDay } from './RequestsWidget.helpers';
import { Styled } from './RequestsWidget.styled';

interface HRViewProps {
	title: string;
	emptyRequestsTitle: string;
	isEmployeeView: boolean;
	shortView?: boolean;
	employeeTimeOffStatics?: TimeOffStaticsType[];
	employeeRequests: RequestTimeOffType[];
	employeeId?: number;
	loading?: GenericType;
	handleApproveAllRequests?: () => void;
	handleDeclineTimeOffRequest?: (
		values: { requestId: number; comment: string | null },
		cb?: () => void,
	) => void;
	handleApproveTimeOffRequest?: (requestId: number) => void;
}

const RequestsWidget: FC<HRViewProps> = ({
	title,
	emptyRequestsTitle,
	isEmployeeView,
	shortView = false,
	employeeTimeOffStatics,
	employeeRequests,
	employeeId,
	loading,
	handleApproveAllRequests,
	handleDeclineTimeOffRequest,
	handleApproveTimeOffRequest,
}) => {
	const navigate = useNavigate();
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	const handleSeeAllRequests = (id?: number) => {
		id && navigate(`${Routes.HRModule}${Routes.Employee}/${id}`);
	};

	const HeaderButton = () => {
		return isEmployeeView ? (
			<Styled.SeeMoreButton onClick={() => employeeId && handleSeeAllRequests(employeeId)}>
				View All
			</Styled.SeeMoreButton>
		) : (
			<HRThemedButton
				type='button'
				buttonType={ButtonTypes.secondary}
				onClick={handleApproveAllRequests}
			>
				Approve all
			</HRThemedButton>
		);
	};

	const numberOfVacationDays = getNumberOfVacationDay(employeeTimeOffStatics);

	return (
		<Styled.AllRequests isMobile={isMobile}>
			{employeeRequests.length === 0 ? (
				<NoRequests title={emptyRequestsTitle} />
			) : (
				<Styled.RequestsWrapper>
					<Styled.RequestsHeader>
						<Styled.RequestTitle>{title}</Styled.RequestTitle>
						{!shortView && !isMobile && HeaderButton()}
					</Styled.RequestsHeader>
					{numberOfVacationDays !== null && (
						<Styled.EmployeeVacationStatics>
							<div>
								{CalendarEmojisByTypes[CALENDAR_EVENT_TYPES.vacation]} Vacation Days Available
							</div>
							<div>
								{numberOfVacationDays} {numberOfVacationDays === 1 ? 'Day' : 'Days'}
							</div>
						</Styled.EmployeeVacationStatics>
					)}
					<Styled.RequestsList>
						{employeeRequests.map((request) => (
							<HRRequestComponent
								key={request.id}
								employeeRequest={request}
								isEmployeeView={isEmployeeView}
								isShortView={shortView}
								loading={loading}
								approveEmployeeTimeOffRRequest={
									handleApproveTimeOffRequest ? handleApproveTimeOffRequest : () => undefined
								}
								declineEmployeeTimeOffRequest={
									handleDeclineTimeOffRequest ? handleDeclineTimeOffRequest : () => undefined
								}
							/>
						))}
					</Styled.RequestsList>
				</Styled.RequestsWrapper>
			)}
		</Styled.AllRequests>
	);
};

export default RequestsWidget;
