import dayjs from 'dayjs';

import { RequestTimeOffType } from 'modules/Common/types/hrModuleTypes';

import { CreateTimeOffRequestType } from './HRRequestEditTimeOffModal.types';

export const formatDayLabel = (day: number) => {
	if (day < 2) {
		return `${day} Working Day`;
	}

	return `${day} Working Days`;
};

export const formatWorkDays = (
	workDays: { date: string; isWorking: boolean }[],
	firstLastDayType: { startType: string; endType: string },
): { [key: string]: string }[] => {
	const daysListArray: { [key: string]: string }[] = [];

	workDays
		.sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1))
		.forEach((workDay, index) => {
			if (index === 0) {
				daysListArray.push({
					[workDay.date]: firstLastDayType.startType === 'FULL' ? 'All Day' : 'Half Day',
				});
			} else if (index === workDays.length - 1) {
				daysListArray.push({
					[workDay.date]: firstLastDayType.endType === 'FULL' ? 'All Day' : 'Half Day',
				});
			} else {
				daysListArray.push({ [workDay.date]: workDay.isWorking ? 'All Day' : 'Non working Day' });
			}
		});

	return daysListArray;
};

export const prepareFormValues = (values: RequestTimeOffType): CreateTimeOffRequestType => {
	return {
		startTimeEndTime: [dayjs(values.startDate), dayjs(values.endDate)],
		firstDayType: values.firstDayType,
		lastDayType: values.lastDayType === 'NONE' ? 'FULL' : values.lastDayType,
		description: values.description,
		type: values.type,
		attachments: values.attachments.map((attachment) => attachment.ref),
		employee: values.employee.id,
	};
};
