import React, { FC } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';

import Table from 'components/Table';
import { EmployeeStatusTypesFormEnum, IEmployeeState } from 'modules/Common/types/employee';
import { IOption } from 'types';

import { EmployeesStatusModal } from '../EmployeesStatusModal/EmployeesStatusModal';
import { TabsTitleEnum } from '../HREmployeesList.constants';
import { prepareEmployeeStatusesList } from '../HREmployeesList.helpers';
import { Styled } from '../HREmployeesList.styled';
import { ChangeActionEnum, StatusModalInfoProps } from '../HREmployeesList.types';

type EmployeeTableForSuperUserProps = {
	search: string;
	onSearchChange: (value: string) => void;
	status: string;
	onStatusChange: (value: string) => void;
	pageSize: number;
	pageIndex: number;
	loading: boolean;
	totalCount: number;
	data: object[];
	columnsData: object[];
	isActiveTab: boolean;
	onTableChange: (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => void;
	onChange: (page: number, size: number) => void;
	onPageSizeChange: (page: number, size: number) => void;
	onTabChange: (key: string) => void;
	modalStatusProps: StatusModalInfoProps | null;
	onModalClose: () => void;
	onModalSubmit: (
		changeAction: ChangeActionEnum,
		employeeId: number,
		values: { employeeStatus: EmployeeStatusTypesFormEnum; startDate?: string; leaveDate?: string },
		cb?: () => void,
	) => void;
	employeeStatusesList: IEmployeeState[];
};

const EmployeeTableForSuperUser: FC<EmployeeTableForSuperUserProps> = ({
	search,
	onSearchChange,
	status,
	onStatusChange,
	pageSize,
	pageIndex,
	loading,
	totalCount,
	data,
	columnsData,
	isActiveTab,
	onTableChange,
	onChange,
	onPageSizeChange,
	onTabChange,
	modalStatusProps,
	onModalClose,
	onModalSubmit,
	employeeStatusesList,
}) => {
	const preparedEmployeeStatusesList = prepareEmployeeStatusesList(employeeStatusesList);
	const noDataAvailable = !data.length && !loading;

	return (
		<Styled.Root>
			<Tabs
				defaultActiveKey={TabsTitleEnum.ActiveTab}
				tabBarStyle={{ textTransform: 'uppercase' }}
				onChange={onTabChange}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						active: true,
						children: (
							<Styled.TableWrap noDataAvailable={noDataAvailable}>
								<Table
									pageSize={pageSize}
									current={pageIndex}
									loading={loading}
									total={totalCount}
									data={data}
									columns={columnsData}
									onTableChange={onTableChange}
									onChange={onChange}
									onPageSizeChange={onPageSizeChange}
								/>
								<span>There are no active users</span>
							</Styled.TableWrap>
						),
					},
					{
						label: 'Archive',
						key: TabsTitleEnum.ArchiveTab,
						children: (
							<Styled.TableWrap noDataAvailable={noDataAvailable}>
								<Table
									pageSize={pageSize}
									current={pageIndex}
									loading={loading}
									total={totalCount}
									data={data}
									columns={columnsData}
									onTableChange={onTableChange}
									onChange={onChange}
									onPageSizeChange={onPageSizeChange}
								/>
								<span>There are no archived users</span>
							</Styled.TableWrap>
						),
					},
				]}
				tabBarExtraContent={
					<Styled.ExtraControls>
						<Styled.Input
							value={search}
							placeholder='Search an employee'
							autoComplete='off'
							prefix={<SearchOutlined style={{ color: 'gray' }} />}
							onChange={(e) => onSearchChange(e.target.value)}
							allowClear
						/>
						{isActiveTab && (
							<Styled.SelectWrap>
								<Styled.SelectLabel>Status:</Styled.SelectLabel>
								<Styled.Select
									value={status}
									onChange={(value: string) => onStatusChange(value)}
									placeholder='Select a status'
									label=''
								>
									{preparedEmployeeStatusesList.map((option: IOption) => (
										<Styled.Option key={option.id} value={option.value}>
											{option.name}
										</Styled.Option>
									))}
								</Styled.Select>
							</Styled.SelectWrap>
						)}
					</Styled.ExtraControls>
				}
			/>
			<EmployeesStatusModal
				statusInfo={modalStatusProps}
				employeeStatusesOptions={employeeStatusesList}
				onCancel={onModalClose}
				handleModalSubmit={onModalSubmit}
			/>
		</Styled.Root>
	);
};

export default EmployeeTableForSuperUser;
