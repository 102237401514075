import React, { useCallback, useEffect, useState, type FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { pick } from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import CalendarEmpty from 'components/SVG/CalendarEmpty';
import { useMount, useUnmount } from 'hooks';
import ViewProfileSidebar from 'modules/Common/components/ViewProfileSidebar';
import { IUser } from 'modules/Common/types';
import { IEmployeeProfileBase, IEmployeeProfileSuperUser } from 'modules/Common/types/employee';
import { RequestTimeOffType, TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import HRRequestComponent from 'modules/HR/components/HRRequestComponent';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import TimeOffUsedStatistics from 'modules/HR/components/TimeOffUsedStatistics';
import { hrDucks } from 'modules/HR/ducks';
import { Routes } from 'modules/HR/routes/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType } from 'types';

import HRAttachmentListForm from '../HRAttachmentListForm';

import { employeeAllowedFields } from './HREmployeeViewProfile.constants';
import { Styled } from './HREmployeeViewProfile.styled';

type HREmployeeViewProfileProps = {
	isSuperUser: boolean;
	employeeDetails: null | IEmployeeProfileBase | IEmployeeProfileSuperUser;
	employeeTimeOffList: RequestTimeOffType[];
	user: IUser;
	loading: GenericType;
	employeeTimeOffStatics: TimeOffStaticsType[];
	getEmployeeByIdRequested: (employeeId: string, isSuperUser: boolean) => void;
	getEmployeeAttachmentsRequested: (employeeId: string, isSuperUser: boolean) => void;
	sendFirstDayInvitationRequested: (employeeId: string) => void;
	declineTimeOffRequestRequested: (
		{ requestId, comment }: { requestId: number; comment: string | null },
		callback?: () => void,
	) => void;
	approveTimeOffRequestRequested: (requestId: number, callback?: () => void) => void;
	resetEmployeeDetails: () => void;
	handleUpdatePageTitle: (title: string) => void;
	getTimeOffRequestsEmployeeRequested: (employeeId: number) => void;
	getTimeOffStaticsPerEmployeeRequested: ({
		employeeId,
		onlyApproved,
	}: {
		employeeId: number;
		onlyApproved?: boolean;
	}) => void;
};

const HREmployeeViewProfile: FC<HREmployeeViewProfileProps> = ({
	isSuperUser,
	employeeDetails,
	employeeTimeOffList,
	employeeTimeOffStatics,
	user,
	loading,
	getEmployeeByIdRequested,
	getEmployeeAttachmentsRequested,
	sendFirstDayInvitationRequested,
	resetEmployeeDetails,
	handleUpdatePageTitle,
	getTimeOffRequestsEmployeeRequested,
	approveTimeOffRequestRequested,
	declineTimeOffRequestRequested,
	getTimeOffStaticsPerEmployeeRequested,
}) => {
	const [upcomingRequests, setUpcomingRequests] = useState<RequestTimeOffType[]>([]);
	const [historicalRequests, setHistoricalRequests] = useState<RequestTimeOffType[]>([]);
	const [newTimeOffRequests, setNewTimeOffRequests] = useState<RequestTimeOffType[]>([]);
	const { employeeId } = useParams();
	const { employee: currentUserId } = user;
	const navigate = useNavigate();
	const showAttachments = isSuperUser || user?.id === employeeDetails?.userId;
	const showTimeOffBlock = currentUserId === Number(employeeId) || isSuperUser;

	const currentDate = dayjs();

	const handleFirstDayInvitation = useCallback(() => {
		employeeId && sendFirstDayInvitationRequested(employeeId);
	}, [employeeId]);

	const handleCheckCalendar = useCallback(() => {
		navigate(`${Routes.HRModule}${Routes.Calendar}`);
	}, []);

	const updateAttachmentsCallback = useCallback(() => {
		getEmployeeAttachmentsRequested(employeeId || '', isSuperUser);
	}, [showAttachments]);

	const refreshCalendarAndRequests = useCallback(() => {
		employeeId && getTimeOffRequestsEmployeeRequested(+employeeId);
	}, [employeeId]);

	const handleApproveTimeOffRequest = (requestId: number) => {
		approveTimeOffRequestRequested(requestId, () => refreshCalendarAndRequests());
	};

	const handleDeclineTimeOffRequest = (
		values: { requestId: number; comment: string | null },
		cb?: () => void,
	) => {
		declineTimeOffRequestRequested(values, () => {
			cb && cb();
			setTimeout(() => {
				refreshCalendarAndRequests();
			}, 250);
		});
	};

	useEffect(() => {
		employeeDetails?.firstName &&
			handleUpdatePageTitle(`${employeeDetails.firstName} ${employeeDetails.lastName}`);
	}, [employeeDetails]);

	useEffect(() => {
		setUpcomingRequests(
			employeeTimeOffList.filter((request) => dayjs(request.endDate).isAfter(currentDate)),
		);
		setHistoricalRequests(
			employeeTimeOffList.filter((request) => dayjs(request.endDate).isBefore(currentDate)),
		);
		isSuperUser &&
			setNewTimeOffRequests(employeeTimeOffList.filter((request) => request.approved === null));
	}, [employeeTimeOffList]);

	useEffect(() => {
		showAttachments && getEmployeeAttachmentsRequested(employeeId || '', isSuperUser);
	}, [showAttachments]);

	useMount(() => {
		if (employeeId) {
			getEmployeeByIdRequested(employeeId, isSuperUser);
			if (currentUserId === +employeeId || isSuperUser) {
				getTimeOffRequestsEmployeeRequested(+employeeId);
				getTimeOffStaticsPerEmployeeRequested({ employeeId: +employeeId });
			}
		}
	});

	useUnmount(() => {
		resetEmployeeDetails();
	});

	const employeeDetailsSanitized = isSuperUser
		? employeeDetails
		: pick(employeeDetails, employeeAllowedFields);

	return (
		<Styled.Root>
			<Styled.LeftColumn>
				<ViewProfileSidebar
					{...employeeDetailsSanitized}
					isSuperUser={isSuperUser}
					loading={!!loading.getEmployeeByIdLoad}
				/>
			</Styled.LeftColumn>
			<Styled.RightColumn>
				{employeeDetails?.showFirstDayInvitation && (
					<Styled.FirstDayInvitation>
						<Styled.FirstDayInvitationText>
							<Styled.FirstDayInvitationHeader>
								First Day Invitation
							</Styled.FirstDayInvitationHeader>
							<Styled.FirstDayInvitationDescription>
								Please use this option to re-send the first day&apos;s invitation. The date of hire
								is{' '}
								{employeeDetails?.startDate
									? dayjs(employeeDetails?.startDate).format('MMMM DD')
									: 'N/A'}
							</Styled.FirstDayInvitationDescription>
						</Styled.FirstDayInvitationText>
						<HRThemedButton
							type='submit'
							buttonType={ButtonTypes.primary}
							loading={false}
							onClick={handleFirstDayInvitation}
							disabled={!!loading?.sendFirstDayInvitationLoad}
							style={{ minWidth: '120px' }}
						>
							Re-Send
						</HRThemedButton>
					</Styled.FirstDayInvitation>
				)}
				{isSuperUser && newTimeOffRequests.length > 0 && (
					<Styled.NewTimeOffRequestWrap>
						<Styled.NewTimeOffRequestTopLine>
							<Styled.NewTimeOffRequestTitle>New Time Off Request</Styled.NewTimeOffRequestTitle>
							<HRThemedButton
								type='submit'
								buttonType={ButtonTypes.secondary}
								loading={false}
								onClick={handleCheckCalendar}
							>
								{'Check Calendar'}
							</HRThemedButton>
						</Styled.NewTimeOffRequestTopLine>
						<Styled.NewTimeOffRequestList>
							{newTimeOffRequests.map((request) => (
								<HRRequestComponent
									key={request.id}
									employeeRequest={request}
									isEmployeeView={!isSuperUser}
									isInBlockView={true}
									loading={loading}
									approveEmployeeTimeOffRRequest={handleApproveTimeOffRequest}
									declineEmployeeTimeOffRequest={handleDeclineTimeOffRequest}
								/>
							))}
						</Styled.NewTimeOffRequestList>
					</Styled.NewTimeOffRequestWrap>
				)}
				{showTimeOffBlock && (
					<Styled.TimeOffBlock>
						<Styled.StatisticWrap>
							<TimeOffUsedStatistics
								statisticData={employeeTimeOffStatics}
								dashboardChart={false}
								loading={loading}
							/>
						</Styled.StatisticWrap>
						<Styled.AllRequests>
							<Styled.RequestsWrapper>
								<Styled.RequestTitle>Upcoming vacations</Styled.RequestTitle>
								{upcomingRequests.length ? (
									<Styled.RequestsList>
										{upcomingRequests.map((request) => (
											<HRRequestComponent
												key={request.id}
												employeeRequest={request}
												isEmployeeView={true}
											/>
										))}
									</Styled.RequestsList>
								) : (
									<Styled.NoRequests>
										<CalendarEmpty width='120px' />
										<p>No upcoming vacations yet</p>
									</Styled.NoRequests>
								)}
							</Styled.RequestsWrapper>
							<Styled.RequestsWrapper>
								<Styled.RequestTitle>History</Styled.RequestTitle>
								{historicalRequests.length ? (
									<Styled.RequestsList>
										{historicalRequests.map((request) => (
											<HRRequestComponent
												key={request.id}
												employeeRequest={request}
												isEmployeeView={true}
											/>
										))}
									</Styled.RequestsList>
								) : (
									<Styled.NoRequests>
										<CalendarEmpty width='120px' />
										<p>No history for time-off yet</p>
									</Styled.NoRequests>
								)}
							</Styled.RequestsWrapper>
						</Styled.AllRequests>
					</Styled.TimeOffBlock>
				)}
				{showAttachments && (
					<Styled.AttachmentsBlock>
						<HRAttachmentListForm
							employeeId={employeeId || ''}
							isSuperUser={isSuperUser}
							attachmentsList={employeeDetails?.attachments || []}
							updateAttachmentsCallback={updateAttachmentsCallback}
						/>
					</Styled.AttachmentsBlock>
				)}
			</Styled.RightColumn>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		employeeDetails: hrDucks.hrSelectors.getEmployeeDetails(state),
		employeeTimeOffList: hrDucks.hrSelectors.getCurrentEmployeeTimeOffRequests(state),
		employeeTimeOffStatics: hrDucks.hrSelectors.getTimeOffStaticsPerEmployee(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
	}),
	{
		getEmployeeByIdRequested: hrDucks.hrActions.getEmployeeByIdRequested,
		getEmployeeAttachmentsRequested: hrDucks.hrActions.getEmployeeAttachmentsRequested,
		sendFirstDayInvitationRequested: hrDucks.hrActions.sendFirstDayInvitationRequested,
		approveTimeOffRequestRequested: hrDucks.hrActions.approveTimeOffRequestRequested,
		declineTimeOffRequestRequested: hrDucks.hrActions.declineTimeOffRequestRequested,
		getTimeOffRequestsEmployeeRequested:
			hrDucks.hrActions.getTimeOffRequestsForEmployeeByIdRequested,
		getTimeOffStaticsPerEmployeeRequested: hrDucks.hrActions.getTimeOffStaticsPerEmployeeRequested,
		resetEmployeeDetails: hrDucks.hrActions.resetEmployeeDetails,
	},
)(HREmployeeViewProfile);
