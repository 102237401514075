import { RequestStatusEnum } from './HRRequest.constants';

export const getRequestStatus = (approved: boolean | null): string => {
	const statusMap: Record<string, string> = {
		null: RequestStatusEnum.requested,
		true: RequestStatusEnum.approved,
		false: RequestStatusEnum.declined,
	};

	return statusMap[String(approved)];
};
