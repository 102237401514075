import React, { type FC } from 'react';
import { Field, useFormState } from 'react-final-form';

import { DatePicker, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FormApi } from 'final-form';

import FieldWrapper from 'components/FieldWrapper';
import { IEmployeeState, IEmployeeValues } from 'modules/Common/types/employee';
import {
	composeValidators,
	endDateValidator,
	requiredFieldValidator,
	textValidator,
} from 'utils/validators';

import { getMinStartDate } from '../CreateEditEmployee.helpers';
import FormBlockLine from '../FormBlockLine';

import { Styled } from './JobDetails.styled';

type JobDetailsProps = {
	dateFormat: string;
	formObj: FormApi<IEmployeeValues, Partial<IEmployeeValues>>;
	disabledFields?: string[];
	userStatus?: IEmployeeState;
	isEditMode: boolean;
	getIsATSEmployee: (roleId: number) => boolean;
};

export const JobDetails: FC<JobDetailsProps> = ({
	dateFormat,
	formObj,
	disabledFields,
	userStatus,
	isEditMode,
	getIsATSEmployee,
}) => {
	const startDate = formObj.getState().values.startDate;
	const minEndDate = startDate ? dayjs(startDate).startOf('day').add(1, 'day') : null;
	const endDate = formObj.getState().values.leaveDate;
	const minStartDate = getMinStartDate(userStatus, endDate, isEditMode);

	const { values } = useFormState();
	const isATSEmployee = getIsATSEmployee(values?.role);
	const isStartDateRequired = !(isATSEmployee || disabledFields?.includes('startDate'));

	return (
		<Styled.FormBlockWrap title='Job Details'>
			<FormBlockLine>
				<Field
					name='startDate'
					validate={isStartDateRequired ? requiredFieldValidator : () => null}
				>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name='startDate'
							label='Start Date'
							required={isStartDateRequired}
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<DatePicker
								{...input}
								format={dateFormat}
								inputReadOnly={false}
								picker='date'
								disabledDate={(current) =>
									minStartDate ? current && current.isAfter(minStartDate, 'day') : false
								}
								disabled={disabledFields?.includes('startDate')}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field
					name='leaveDate'
					validate={(value: Dayjs) => endDateValidator(formObj.getState().values.startDate, value)}
				>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name='leaveDate'
							label='Leaving Date'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<DatePicker
								{...input}
								format={dateFormat}
								inputReadOnly={false}
								picker='date'
								disabledDate={(current) => {
									return minEndDate ? current && current < minEndDate : false;
								}}
								disabled={disabledFields?.includes('leaveDate')}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field
					name='jobTitle'
					validate={
						isATSEmployee || disabledFields?.includes('jobTitle')
							? () => null
							: composeValidators(requiredFieldValidator, textValidator)
					}
				>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								isFixed
								name='jobTitle'
								label='Job Title'
								required={!isATSEmployee}
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									placeholder='Enter Job Title'
									autoComplete='off'
									disabled={disabledFields?.includes('jobTitle')}
								/>
							</FieldWrapper>
						</>
					)}
				</Field>
				<Field name='departmentName'>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='departmentName'
								label='Department'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									placeholder='Enter Department'
									autoComplete='off'
									disabled={disabledFields?.includes('departmentName')}
								/>
							</FieldWrapper>
						</>
					)}
				</Field>
			</FormBlockLine>
		</Styled.FormBlockWrap>
	);
};
