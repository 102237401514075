type AttachmentType = {
	id: number;
	ref: string;
	originalFileName: string;
	created: string;
	request: number;
};

type EmployeeType = {
	id: number;
	fullName: string;
	photoRef: string | null;
};

export type TimeOffType =
	| 'VACATION'
	| 'PARENTAL'
	| 'ILLNESS'
	| 'OTHER'
	| 'BANK_HOLIDAY'
	| 'COMPANY_HOLIDAY';

export type DayType = 'FULL' | 'HALF_AM' | 'HALF_PM' | 'NONE';

export type RequestTimeOffType = {
	id: number;
	startDate: string;
	endDate: string;
	type: TimeOffType;
	firstDayType: DayType;
	lastDayType: DayType;
	description: string;
	employee: EmployeeType;
	reviewer: number | null;
	reviewTime: string | null;
	created: string;
	attachments: AttachmentType[];
	approved: boolean | null;
};

type CalendarColors = {
	color: string;
	bgColor: string;
};

export type CalendarEventType = {
	id: number;
	type: string;
	name?: string;
	description: string;
	employeeId?: number;
	employeeFullName?: string;
	employeePhotoUuid?: string | null;
	start: Date;
	end: Date;
	firstDayType?: string;
	lastDayType?: string;
	isApproved: boolean | null;
	reviewer?: number | null;
	reviewTime?: string | null;
	colors?: CalendarColors | null;
	icon: string;
	iconBackgroundColor?: string;
};

export enum OnOffBoardingSettingTypes {
	ONBOARDING = 'ONBOARDING',
	OFFBOARDING = 'OFFBOARDING',
}

export type OnOffBoardingSettingType = {
	id: number;
	type: OnOffBoardingSettingTypes;
	description: string;
	attachments: string[];
	useAsDefault: boolean;
};

export type DateType = { start: Date; end: Date };

export type BankHolidayBaseType = {
	date: string;
	name: string;
	description: string;
	everyYear: boolean;
};

export type BankHolidayType = BankHolidayBaseType & {
	id: number;
	client: number | null;
};

export type TimeOffStaticsType = {
	timeOffType: TimeOffType;
	usedDays: number;
	totalDays: number;
};
