import { COLORS } from 'theme';

export enum RequestStatusEnum {
	approved = 'Approved',
	declined = 'Declined',
	requested = 'Requested',
}

export const RequestColorsByTypes = {
	[RequestStatusEnum.approved]: { color: COLORS.green, bgColor: '#f6ffed', borderColor: '#b7eb8f' },
	[RequestStatusEnum.declined]: { color: COLORS.red, bgColor: '#fff2e8', borderColor: '#ffbb96' },
	[RequestStatusEnum.requested]: {
		color: COLORS.blue6,
		bgColor: '#e6f7ff',
		borderColor: '#91d5ff',
	},
};
