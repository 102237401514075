import React, { useCallback, useMemo, useRef, type FC } from 'react';
import { Field, Form } from 'react-final-form';

import { Input, DatePicker, Checkbox, Tooltip, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import InfoIcon from 'components/SVG/InfoIcon';
import { BankHolidayBaseType, BankHolidayType } from 'modules/Common/types/hrModuleTypes';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { GenericType } from 'types';
import { trimSpacesFormValues } from 'utils/helpers';
import { requiredFieldValidator } from 'utils/validators';

import { Styled } from './AddEditCustomHolidayModal.styled';

type AddEditCustomHolidayModalProps = {
	isOpen: number | boolean;
	holidaysList: BankHolidayType[];
	loading: GenericType;
	onCancel: () => void;
	handleAddHoliday: (bankHoliday: BankHolidayBaseType, cb?: () => void) => void;
	handleUpdateHoliday: (bankHoliday: Omit<BankHolidayType, 'client'>, cb?: () => void) => void;
};

const prepareInitialValues = (values: BankHolidayType | undefined) => {
	if (!values) return {};

	const { client, ...rest } = values;

	return {
		...rest,
		date: dayjs(rest.date),
	};
};

export const AddEditCustomHolidayModal: FC<AddEditCustomHolidayModalProps> = ({
	isOpen,
	holidaysList,
	loading,
	onCancel,
	handleAddHoliday,
	handleUpdateHoliday,
}) => {
	const isEditMode = typeof isOpen === 'number';
	const formRef = useRef<FormApi<unknown>>(null);

	const initialValues = useMemo(
		() => ({
			name: '',
			date: '',
			description: '',
			everyYear: true,
			...(isEditMode
				? prepareInitialValues(holidaysList.find((event) => event.id === isOpen))
				: {}),
		}),
		[isOpen],
	);

	const handleCloseModal = () => {
		formRef.current?.reset();
		onCancel();
	};

	const onSubmit = useCallback(
		(values: Partial<BankHolidayBaseType>) => {
			const valuesToSubmit = trimSpacesFormValues({
				...values,
				date: dayjs(values.date).format('YYYY-MM-DD'),
			});
			if (isEditMode) {
				handleUpdateHoliday(valuesToSubmit as Omit<BankHolidayType, 'client'>, handleCloseModal);
			} else {
				handleAddHoliday(valuesToSubmit as BankHolidayBaseType, handleCloseModal);
			}
		},
		[isOpen],
	);

	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={handleCloseModal} width='auto'>
			<Styled.ModalHead>
				<Styled.ModalTitle>
					<h3>{isEditMode ? 'Edit Company Holiday' : 'Add Company Holiday'}</h3>
					<Tooltip title='Additional company wide days off can be added' trigger={['hover']}>
						<Button type='ghost' size='small'>
							<InfoIcon width='20px' height='20px' />
						</Button>
					</Tooltip>
				</Styled.ModalTitle>
				<Styled.UserHint>
					Please note any already approved leave may need to be manually adjusted
				</Styled.UserHint>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					autoComplete='off'
					render={({ handleSubmit, submitting, form }) => {
						formRef.current = form;

						return (
							<form onSubmit={handleSubmit}>
								<Styled.FieldsBlock>
									<Field name='name' validate={requiredFieldValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='name'
												label='Holiday Name'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<Input {...input} placeholder='e.g. Company B-Day' autoComplete='off' />
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldsBlock>
								<Styled.FieldsBlock>
									<Field name='date' validate={requiredFieldValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='date'
												label='Day'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<DatePicker
													{...input}
													format='MMMM DD'
													picker='date'
													placeholder='DD/MM/YYYY'
												/>
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldsBlock>
								<Styled.FieldsBlock>
									<Field name='description'>
										{({ input, meta }) => (
											<FieldWrapper
												name='description'
												label='Description'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<TextArea
													{...input}
													placeholder='Enter holiday description '
													autoSize={{ minRows: 8, maxRows: 8 }}
												/>
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldsBlock>
								<Styled.FieldsBlock>
									<Field name='everyYear' type='checkbox'>
										{({ input, meta }) => (
											<FieldWrapper name='everyYear'>
												<Checkbox {...input}>Every Year</Checkbox>
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldsBlock>
								<Styled.ButtonBox>
									<HRThemedButton
										type='submit'
										buttonType={ButtonTypes.primary}
										disabled={
											!!loading?.createCustomHolidayLoad || !!loading?.updateCustomHolidayLoad
										}
									>
										Save Changes
									</HRThemedButton>
									<HRThemedButton
										type='button'
										buttonType={ButtonTypes.tertiary}
										onClick={onCancel}
									>
										Cancel
									</HRThemedButton>
								</Styled.ButtonBox>
							</form>
						);
					}}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
