import dayjs, { Dayjs } from 'dayjs';

import { MESSAGES } from 'modules/Common/constants';
import {
	EmployeeStatusEnum,
	IEmployeeAttachment,
	IEmployeeState,
	IEmployeeValues,
} from 'modules/Common/types/employee';
import { UserRolesEnum } from 'types';
import { composeValidators, requiredFieldValidator, textValidator } from 'utils/validators';

import { CreateEmployeeStateFromAtsType } from './CreateEditEmployee.types';

export const normalizeEmployeeStateData = (
	data: CreateEmployeeStateFromAtsType,
	isEditMode: boolean,
) => {
	if (isEditMode || !data) return {};

	const fullName = data.fullName.split(' ');
	const firstName = fullName[0] || '';
	const lastName = fullName.slice(1).join(' ') || '';

	return {
		firstName,
		lastName,
		personalPhone: data.phoneNumber || '',
		jobTitle: data.jobTitle || '',
	};
};

export const prepareEmployeeValues = (values: IEmployeeValues) => ({
	...values,
	startDate: values.startDate ? dayjs(values.startDate) : null,
	leaveDate: values.leaveDate ? dayjs(values.leaveDate) : null,
	birthdayDate: values.birthdayDate ? dayjs(values.birthdayDate) : null,
	role: values?.role || values?.role?.id || null,
});

export const prepareEmployeeAttachments = (attachments: IEmployeeAttachment[]) => {
	return attachments.map((attachment, index) => ({
		uid: `${index}`,
		name: attachment.originalFileName || attachment,
		id: attachment.id || attachment,
		status: 'done',
		response: {
			data: attachment.ref || attachment,
		},
	}));
};

export const getMinStartDate = (
	userStatus: IEmployeeState | undefined,
	endDate: string | null,
	isEditMode: boolean,
): Dayjs | null => {
	const minStartDateWithoutPendingStatus =
		userStatus?.name === EmployeeStatusEnum.PENDING || !isEditMode ? null : dayjs().startOf('day');
	const minStartDateWithEndingDate = endDate ? dayjs(endDate).subtract(1, 'day') : null;

	if (minStartDateWithoutPendingStatus && minStartDateWithEndingDate) {
		return minStartDateWithoutPendingStatus.isAfter(minStartDateWithEndingDate)
			? minStartDateWithEndingDate
			: minStartDateWithoutPendingStatus;
	} else {
		return minStartDateWithEndingDate || minStartDateWithoutPendingStatus;
	}
};

export const dynamicFormValidation = (values: IEmployeeValues, disabledFields: string[]) => {
	const errors = {};

	if (
		values.role === UserRolesEnum.HR_EMPLOYEE &&
		!disabledFields.includes('jobTitle') &&
		composeValidators(requiredFieldValidator, textValidator)(values.jobTitle)
	) {
		errors.jobTitle = MESSAGES.mandatoryField;
	}

	if (
		values.role === UserRolesEnum.HR_EMPLOYEE &&
		!disabledFields.includes('startDate') &&
		requiredFieldValidator(values.startDate)
	) {
		errors.startDate = MESSAGES.mandatoryField;
	}

	return errors;
};
