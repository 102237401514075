import React, { type FC } from 'react';

import { ReactParent } from 'types';

import { Styled } from './TimeOffStatisticLine.styled';

type TimeOffStatisticLineProps = ReactParent & {
	name: string;
	emoji: string;
	color: string;
	daysUsed: number;
	totalDays: number;
};

const TimeOffStatisticLine: FC<TimeOffStatisticLineProps> = ({
	name,
	color,
	daysUsed,
	totalDays,
	emoji,
}) => {
	const percentUsed = totalDays === 0 ? 0 : Math.round((daysUsed / totalDays) * 100);

	return (
		<Styled.Root>
			{emoji}
			<Styled.StatisticName>{name}:</Styled.StatisticName>
			<Styled.ProgressBarContainer>
				<Styled.ProgressBar percentage={percentUsed} color={color} />
				<Styled.PercentText percentage={percentUsed} color={color}>
					{percentUsed}%
				</Styled.PercentText>
				{percentUsed < 80 && (
					<Styled.ProgressDaysUsedText percentage={percentUsed}>
						{daysUsed}
					</Styled.ProgressDaysUsedText>
				)}
				<Styled.ProgressDaysTotalText>{totalDays} Days</Styled.ProgressDaysTotalText>
			</Styled.ProgressBarContainer>
		</Styled.Root>
	);
};

export default TimeOffStatisticLine;
