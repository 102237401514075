import { TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import { CALENDAR_EVENT_TYPES } from 'modules/HR/constants/HRModuleConstants.constants';

export const getNumberOfVacationDay = (statistic?: TimeOffStaticsType[]): number | null => {
	const vacationStat = statistic?.find(
		(stat) => stat.timeOffType === CALENDAR_EVENT_TYPES.vacation,
	);

	return vacationStat ? vacationStat.totalDays - vacationStat.usedDays : null;
};
