import queryString from 'querystring';

import dayjs from 'dayjs';
import { convertToRaw } from 'draft-js';
import { isEmpty, mapValues, trim, isString } from 'lodash';

import API from 'api';
import { getEmployeePhotoByUUID } from 'api/endpoints/employee';
import { AttachmentTypesEnum } from 'modules/Common/types';
import { convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { DATE_FORMAT_UK, DATE_FORMAT_US } from 'modules/HR/constants/HRModuleConstants.constants';
import { CurrencyType, DataFormatEnum, RequestsEnum } from 'types';
import { LocalStorageType } from 'types/storage';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const fullMonths = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const getAcronyms = (value) => {
	return value
		.split(' ')
		.map((item) => item[0])
		.join('');
};

// TODO Find more suitable solution for getting needed props (lodash)
export const propsFilter = (data, props) => {
	return data?.map((item, index) => ({
		key: `${item.id}-${index}`,
		...Object.keys(item)?.reduce((acc, cur) => {
			if (props.includes(cur)) {
				acc[cur] = item[cur] ?? '-';
			}

			return acc;
		}, {}),
	}));
};

export const getCounter = (time, timeUnit = 'D') => {
	const timeUnitOptions = {
		D: `${time ? time + ' day' : ''}${time > 1 ? 's ' : ' '}`,
		W: `${time ? time + ' week' : ''}${time > 1 ? 's ' : ' '}`,
		M: `${time ? time + ' month' : ''}${time > 1 ? 's ' : ' '}`,
	};

	return timeUnitOptions[timeUnit];
};

export const getUTCDate = (date) => {
	if (date) {
		const dateObj = new Date(date);
		const year = dateObj.getUTCFullYear();
		const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
		const day = String(dateObj.getUTCDate()).padStart(2, '0');
		const hours = String(dateObj.getUTCHours()).padStart(2, '0');
		const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
		const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');

		return { year, month, day, hours, minutes, seconds };
	}

	return null;
};

export const getDate = (date) => {
	if (date) {
		const dateObj = new Date(date);
		const year = dateObj.getFullYear();
		const month = String(dateObj.getMonth() + 1).padStart(2, '0');
		const day = String(dateObj.getDate()).padStart(2, '0');
		const hours = String(dateObj.getHours()).padStart(2, '0');
		const minutes = String(dateObj.getMinutes()).padStart(2, '0');

		return { year, month, day, hours, minutes };
	}

	return null;
};

export const getDateString = (date) => {
	if (!date) return null;

	const { year, month, day } = getDate(date);

	return `${year}-${month}-${day}`;
};

export const getDateFormat = (date, format = DataFormatEnum.UK) => {
	const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

	if (date && isoDateRegex.test(date)) {
		const { day, month, year, hours, minutes } = getDate(date);

		const formatted = {
			[DataFormatEnum.UK]: `${day}/${month}/${year}`,
			[DataFormatEnum.US]: `${month}/${day}/${year}`,
			[DataFormatEnum.Full]: `${months[month - 1]} ${day}, ${year}`,
			[DataFormatEnum.FullMonth]: `${day}th ${fullMonths[month - 1]} , ${year}`,
			[DataFormatEnum.FullHoursMinutes]: `${
				months[month - 1]
			} ${day}, ${year}, ${hours}:${minutes}`,
		};

		return formatted[format];
	}

	return null;
};

export const getDateAndTime = (originalDateString, params = {}) => {
	const { withSeconds = false, endDayFormat = false, isUTCFormatting = true } = params;

	if (!originalDateString) return null;

	const originalDate = dayjs(originalDateString);
	const { year, month, day, hours, minutes, seconds } = isUTCFormatting
		? getUTCDate(originalDate)
		: getDate(originalDate);

	if (withSeconds) {
		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
	}
	if (endDayFormat) {
		return `${year}-${month}-${day}T23:59:59Z`;
	}

	return `${year}-${month}-${day}T${hours}:${minutes}Z`;
};

export const qs = (params) => (!isEmpty(params) ? `?${queryString.stringify(params)}` : '');

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const saveFile = async (blob, name, format, timeout = 30000) => {
	const a = document.createElement('a');
	a.download = format ? `${name}${format}` : name;
	a.href = URL.createObjectURL(blob);
	a.addEventListener('click', () => {
		setTimeout(() => URL.revokeObjectURL(a.href), timeout);
	});
	a.click();
};

export const handleFileDownload = async (uuid, fileName) => {
	const fileUrl = `${process.env.REACT_APP_API_URL}/file/original/${uuid}`;
	downloadFile(fileUrl, fileName);
};

export const handleOpenThreadFileDownload = async (uuid, fileName, openEndpointParams) => {
	const { pwd, threadId } = openEndpointParams || {};
	const fileUrl = `${process.env.REACT_APP_API_URL}${RequestsEnum.OpenEmailThread}/${threadId}${RequestsEnum.File}/${uuid}?pwd=${pwd}`;
	downloadFile(fileUrl, fileName);
};

const downloadFile = async (fileUrl, fileName) => {
	const fileBlob = await API.ticketsService.getFileAttachment(fileUrl);
	const lastFilenameDot = fileName.lastIndexOf('.');
	const format = fileName.substring(lastFilenameDot, fileName.length);
	const name = fileName.substring(0, lastFilenameDot);
	saveFile(fileBlob, name, format, 10);
};

export const downloadCsv = (requestFunc, fileName) => async (event) => {
	event.preventDefault();

	const csv = await requestFunc();
	const blob = new Blob([csv], { type: 'text/csv' });

	saveFile(blob, fileName, '.csv');
};

export const downloadZip = (requestFunc, fileName) => async (event) => {
	event?.preventDefault && event?.preventDefault();
	const file = await requestFunc();

	saveFile(file, fileName, null, 10);
};

export const getTableParams = (page, params) =>
	!isEmpty(params) ? qs({ page: page > 0 ? page - 1 : 0, ...params }) : '';

export const getFromLocalStorage = (key) => {
	const data = key && localStorage.getItem(key);

	return data && JSON.parse(data);
};

export const checkAllowedRoute = (pathname, params, routeRoleMap, userRoles) => {
	const requiredRoles = routeRoleMap[pathname];

	// Check if the pathname starts with any route in routeRoleMap
	const isAllowedRoutesStartsWidth = Object.keys(routeRoleMap)?.some((route) =>
		pathname.startsWith(route),
	);

	// If the route doesn't have required roles, it's not accessible to all
	if (!requiredRoles && !isAllowedRoutesStartsWidth) return false;

	// Check if any of the required roles is true in the userRoles object
	const isAllowedRoles = requiredRoles?.some((role) => userRoles[role]);

	return isEmpty(params)
		? isAllowedRoles && isAllowedRoutesStartsWidth
		: isAllowedRoutesStartsWidth;
};

export const getAmountTimeLeft = (date) => {
	if (!date) return 0;

	const dayjsDate = dayjs(date).toDate();
	const currentDateTime = dayjs().second(0).millisecond(0).toDate();

	return dayjs(dayjsDate).diff(currentDateTime, 'minutes');
};

export const getInterviewResendTimes = (interviewId) => {
	const storedInterviewsTime = localStorage.getItem(LocalStorageType.InterviewResentTimes);
	const interviewsTime = storedInterviewsTime && JSON.parse(storedInterviewsTime);
	const curInterviewsTime = interviewsTime?.find((i) => i.id === interviewId);

	return curInterviewsTime ? getAmountTimeLeft(curInterviewsTime?.date) : null;
};

export const getCVCLDownloadUrls = (candidateAttachments) => {
	const baseUrl = `${process.env.REACT_APP_API_URL}`;

	const CV = candidateAttachments?.find((file) => file.attachmentType === AttachmentTypesEnum.CV);
	const CL = candidateAttachments?.find((file) => file.attachmentType === AttachmentTypesEnum.CL);

	const cvDownloadUrl = `${baseUrl}/file/original/${
		CV?.s3FileReference?.referenceUuid || CV?.referenceUuid
	}`;
	const clDownloadUrl = `${baseUrl}/file/original/${
		CL?.s3FileReference?.referenceUuid || CL?.referenceUuid
	}`;

	return {
		CV,
		CL,
		cvDownloadUrl,
		clDownloadUrl,
	};
};

export const getBudleInputWidth = (bundleWidth) => {
	if (bundleWidth === 1) {
		return '100%';
	}
	if (bundleWidth === 6) {
		return '99px';
	}
	if (bundleWidth > 6) {
		return '95px';
	}

	return '100px';
};

export const getBooleanOrDefault = (value, defaultValue) =>
	typeof value === 'boolean' ? value : defaultValue;

export const getAttachmentDownloadUrl = (file) => {
	const baseUrl = `${process.env.REACT_APP_API_URL}`;

	return `${baseUrl}/file/original/${file}`;
};

export const truncateString = (
	str: string,
	maxCharacters: number,
): { truncated: string; fullText: string } => {
	if (!isString(str)) {
		return null;
	}
	if (str.length > maxCharacters) {
		return { truncated: str.substring(0, maxCharacters) + '...', fullText: str };
	} else {
		return { truncated: str, fullText: str };
	}
};

export const removeSpacesUppercaseFirstLetter = (string) => {
	if (!string) return string;

	return string
		.split(' ')
		.map((word) => word[0].toUpperCase() + word.slice(1))
		.join('');
};

export const getMonthName = (dateStr) => {
	const [year, month] = dateStr.split('-');
	const date = new Date(year, month - 1);

	return getMonthNameFromDate(date);
};

export const getMonthNameFromDate = (date) => {
	return date && date.toLocaleString('default', { month: 'long' });
};

export const getExistingFields = (mainArray, targetData) =>
	mainArray?.reduce((acc, obj) => {
		acc[obj.value] = targetData?.some((item) => item.name === obj.name);

		return acc;
	}, {});

export const handleBtoReference = (btoReference) => {
	const manualLabelIndex = btoReference.indexOf('_manual');
	const isManualInvoice = manualLabelIndex !== -1;
	const preparedBtoReference = btoReference.slice(0, manualLabelIndex);

	return { isManualInvoice, preparedBtoReference };
};

export const formatInvoiceBtoReference = (invoice) => {
	const { isManualInvoice, preparedBtoReference } = handleBtoReference(invoice.btoReference);

	if (isManualInvoice) {
		return {
			...invoice,
			btoReference: preparedBtoReference,
			isManualInvoice: true,
		};
	}

	return invoice;
};

export const getMenuPath = (pathname) => {
	const parts = pathname?.split('/');

	return parts[parts.length - 1];
};

export const trimSpacesFormValues = (obj: { [key: string]: unknown }): { [key: string]: unknown } => {
	const trimmedObj: { [key: string]: unknown } = {};

	for (const key in obj) {
		const value = obj[key];

		if (typeof value === 'string') {
			trimmedObj[key] = value.trim();
		} else if (Array.isArray(value)) {
			trimmedObj[key] = value.map(item => {
				if (typeof item === 'string') {
					return item.trim();
				} else if (typeof item === 'object' && item !== null) {
					return trimSpacesFormValues(item);
				}

				return item;
			});
		} else if (typeof value === 'object' && value !== null) {
			trimmedObj[key] = trimSpacesFormValues(value);
		} else {
			trimmedObj[key] = value;
		}
	}

	return trimmedObj;
};

export const createLegendItem = (chart, index, label, ul, normalizeValueFunc) => {
	if (label === 'Empty Data') return;
	const li = document.createElement('li');
	li.style.cursor = 'pointer';
	li.style.display = 'flex';
	li.style.alignItems = 'center';

	// Create color box
	const colorBox = document.createElement('span');
	colorBox.classList.add('legendBox');
	const color = chart.data.datasets[0].backgroundColor[index];
	colorBox.style.backgroundColor = color;

	// Set text label
	const text = document.createElement('span');
	text.classList.add('legendText');
	text.textContent = label;

	// Set value information
	const value = document.createElement('span');
	value.classList.add('legendValue');
	const numberToShow = chart.data.datasets[0].data[index];
	value.textContent = normalizeValueFunc ? `${normalizeValueFunc(numberToShow)}` : numberToShow;

	// Append color box, text, and value to li
	li.appendChild(colorBox);
	li.appendChild(text);
	li.appendChild(value);

	// Toggle visibility on click
	li.onclick = () => {
		const meta = chart.getDatasetMeta(0);
		meta.data[index].hidden = !meta.data[index].hidden;
		chart.update();
		if (chart.getDatasetMeta(0).data[index]?.hidden) {
			text.style.textDecoration = 'line-through';
			value.style.textDecoration = 'line-through';
		} else {
			text.style.textDecoration = 'none';
			value.style.textDecoration = 'none';
		}
	};

	// Append li to ul
	ul.appendChild(li);
};

export const trimFalsyFields = (values) => {
	if (typeof values === 'object') {
		return Object.entries(values).reduce((acc, [key, value]) => {
			if (value) {
				acc[key] = value;
			}

			return acc;
		}, {});
	}
};

export const defaultFilterInterviewFunc = (currentDate) => (interview) =>
	dayjs(interview.startDate).add(60, 'minute').isAfter(currentDate);

export const convertFieldInputToHtml = (text) => {
	const contentState = text.getCurrentContent();
	if (!contentState.hasText()) return '';

	const raw = convertToRaw(contentState);

	return convertContentToHtml(JSON.stringify(raw))?.props?.dangerouslySetInnerHTML.__html;
};

const urlPrefix = 'https://';
const availablePrefixes = ['https://', 'http://'];

export const prefixLink = (originalLink: string): string =>
	availablePrefixes.some((prefix) => originalLink.startsWith(prefix))
		? originalLink
		: `${urlPrefix}${originalLink}`;

export const isJSONString = (str: string): boolean => {
	try {
		JSON.parse(str);

		return true;
	} catch (e) {
		return false;
	}
};

export const getCurrencySymbolByName = (currency?: string): string => {
	if (!currency) return '';

	const currencyUppercase = currency.toUpperCase();
	if (currencyUppercase === 'GBP') return CurrencyType.PoundSterling;
	if (currencyUppercase === 'USD') return CurrencyType.USDollar;
};

export const filterSelectOptions = (inputVal: string, option, optionName = 'label') =>
	(option?.[optionName]?.toLocaleLowerCase() ?? '').includes(inputVal?.toLocaleLowerCase());

export const capitalizeFirstLetterOfEachWord = (str: string) => {
	const lowercaseStr = str.toLowerCase();

	return lowercaseStr.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const fetchEmployeePhotoWithCredentials = async (
	photoId: string | null,
	setImageUrl: (link: string) => void,
) => {
	if (photoId) {
		try {
			const photoUrl = await getEmployeePhotoByUUID(photoId);
			if (photoUrl) {
				setImageUrl(photoUrl);
			}
		} catch (error) {
			console.error('Error fetching image:', error);
		}
	}
};

const colors = [
	'#FF5733',
	'#33FF57',
	'#3357FF',
	'#FF33A1',
	'#A133FF',
	'#33FFA1',
	'#FF8C33',
	'#8C33FF',
	'#33FF8C',
	'#FF3333',
	'#33FF33',
	'#3333FF',
	'#FF9933',
	'#33FF99',
	'#9933FF',
	'#FF3399',
	'#9933FF',
	'#33FF99',
	'#FFCC33',
	'#33FFCC',
	'#CC33FF',
	'#FF33CC',
	'#CC33FF',
	'#33FFCC',
	'#FF6633',
	'#33FF66',
	'#6633FF',
	'#FF3366',
	'#6633FF',
	'#33FF66',
	'#FFCC99',
	'#99FFCC',
	'#CC99FF',
	'#FF99CC',
	'#CC99FF',
	'#99FFCC',
];

export const getBackgroundColor = (id: number) => {
	return colors[id % colors.length];
};

export const getCountryDateFormate = (isUK: boolean) => {
	return isUK ? DATE_FORMAT_UK : DATE_FORMAT_US;
};

export const formatDateRange = (startDate: string, endDate: string) => {
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	if (start.isSame(end, 'day')) {
		return start.format('MMM D, YYYY');
	} else if (start.isSame(end, 'month')) {
		return `${start.format('MMM D')} - ${end.format('D, YYYY')}`;
	} else if (start.isSame(end, 'year')) {
		return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`;
	} else {
		return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
	}
};

export const getYearsBetweenDates = (dates: [startDate: Date, endDate: Date] | null): number[] => {
	if (!dates) return [];
	const [startDate, endDate] = dates;
	const years: number[] = [];
	const start = new Date(startDate).getFullYear();
	const end = new Date(endDate).getFullYear();

	for (let year = start; year <= end; year++) {
		years.push(year);
	}

	return years;
};
