import React, { useEffect, useLayoutEffect } from 'react';

import API from 'api';
import { Routes } from 'types/routes';

export const VersionProvider = () => {
	useLayoutEffect(() => {
		const handleScaleChange = () => {
			const fixedScalePaths = [Routes.HRModule];

			const currentPath = window.location.pathname;
			const shouldUseFixedScale = fixedScalePaths.some((path) => currentPath.startsWith(path));

			const scale = shouldUseFixedScale ? 1.0 : 1 / window.devicePixelRatio;

			document
				?.querySelector('meta[name=viewport]')
				?.setAttribute('content', `width=device-width, initial-scale=${scale}`);
		};

		// Initial setup
		handleScaleChange();

		// Listen for route changes
		window.addEventListener('popstate', handleScaleChange);

		// Listen for history changes
		const originalPushState = window.history.pushState;
		const originalReplaceState = window.history.replaceState;

		window.history.pushState = function (...args) {
			originalPushState.apply(this, args);
			handleScaleChange();
		};

		window.history.replaceState = function (...args) {
			originalReplaceState.apply(this, args);
			handleScaleChange();
		};

		return () => {
			window.removeEventListener('popstate', handleScaleChange);
			window.history.pushState = originalPushState;
			window.history.replaceState = originalReplaceState;
		};
	}, []);

	useEffect(() => {
		const getVersion = async () => {
			const beVerions = await API.versionService.getBackendVersion();

			console.info('backend version ', beVerions);

			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const packageJSON = require('../../../package.json');

			console.info('frontend version', packageJSON.version);
		};
		getVersion();
	}, []);

	return <></>;
};
