import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type RootProps = {
	isDashboardChart: boolean;
	isMobile: boolean;
};

type StatisticsContainerProps = {
	direction?: string;
};

export namespace Styled {
	export const Root = styled.div<RootProps>`
		width: 100%;
		${({ isDashboardChart, isMobile }) =>
		isDashboardChart &&
			`
			background-color: ${COLORS.white};
            border-radius: 10px;
            box-shadow: ${isMobile ? 'none' : '0px 2px 4px 0px rgba(99, 111, 122, 0.12)'};
            padding: ${isMobile ? '24px 0 0' : '24px'};
            display: flex;
            flex-direction: column;
            gap: 16px;
        `}
	`;

	export const Title = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		letter-spacing: -0.22px;
		text-transform: capitalize;
		margin-bottom: 12px;
	`;

	export const SecondaryTitle = styled.div`
		color: ${COLORS.darkGray3};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: -0.176px;
		text-transform: uppercase;
	`;

	export const TitleContainer = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	`;

	export const LinkToPersonalStatistics = styled.div`
		display: flex;
		align-items: center;
		gap: 8px;
		color: ${COLORS.blue};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		cursor: pointer;
	`;

	export const StatisticsContainer = styled.div<StatisticsContainerProps>`
		display: flex;
		flex-direction: ${(props) => props.direction || 'row'};
		gap: 16px;
		flex-wrap: wrap;
	`;
}
