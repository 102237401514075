import { COLORS } from 'theme';

export type StatisticDataType = {
	vacationsUsed?: number;
	vacationsByDefault?: number;
	sickLeaveUsed?: number;
	sickLeaveByDefault?: number;
	parentalUsed?: number;
	parentalByDefault?: number;
	otherUsed?: number;
	otherByDefault?: number;
};

export enum TimeOffTypes {
	VACATION = 'VACATION',
	ILLNESS = 'ILLNESS',
	MEDICAL = 'MEDICAL',
	PARENTAL = 'PARENTAL',
	OTHER = 'OTHER',
}

interface TimeOff {
	color: string;
	emoji: string;
}

export const TimeOffs: Record<TimeOffTypes, TimeOff> = {
	[TimeOffTypes.VACATION]: {
		color: COLORS.green3,
		emoji: '🌴',
	},
	[TimeOffTypes.ILLNESS]: {
		color: COLORS.yellow,
		emoji: '💊',
	},
	[TimeOffTypes.MEDICAL]: {
		color: COLORS.blue6,
		emoji: '🏥',
	},
	[TimeOffTypes.PARENTAL]: {
		color: COLORS.orange,
		emoji: '🐣',
	},
	[TimeOffTypes.OTHER]: {
		color: COLORS.purple,
		emoji: '📅',
	},
};
