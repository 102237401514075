import { Modal as ModalAntd, Select as SelectAntd, Radio } from 'antd';
import DraggerAntd from 'antd/es/upload/Dragger';
import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

const { Group } = Radio;

interface DateRangeInputProps {
	isMobile: boolean;
}

export namespace Styled {
	export const Modal = styled(ModalAntd)`
		max-width: 100%;
		@media ${device.tablet} {
			width: 100%;
			min-height: 100%;
			border-radius: 0;
		}
		.ant-modal-content {
			padding: 24px;
			@media ${device.tablet} {
				min-height: 100vh;
				border-radius: 0;
			}
		}
	`;
	export const ModalHead = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		margin-bottom: 24px;
	`;

	export const ModalContent = styled.div``;

	export const AvailableDaysOffNote = styled.div`
		border-radius: 4px;
		background: ${COLORS.lightBlue7};
		padding: 8px 16px;
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 24px;
		span {
			font-weight: 600;
		}
	`;

	export const TotalRequestedDayOffCalculation = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 4px;
		border: 1px solid ${COLORS.lightGray1};
		background: ${COLORS.lightGray3};
		padding: 16px;
		span {
			font-weight: 600;
		}
	`;

	export const DayOffCalculationMobile = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		align-items: center;
		border-radius: 4px;
		border: 1px solid ${COLORS.lightGray1};
		background: ${COLORS.lightGray3};
		padding: 16px;
	`;

	export const DayOffCalculationMobileHeader = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		margin-bottom: 16px;
	`;

	export const DayOffCalculationMobileList = styled.div`
		display: flex;
		flex-direction: column;
		gap: 16px;
		.ant-collapse-header {
			padding: 0 !important;
		}
		.ant-collapse-header-text {
			flex: auto !important;
		}
		.ant-collapse-expand-icon {
			padding-inline-start: 0;
			svg {
				width: 16px;
			}
		}
		.ant-collapse-content-box {
			padding: 0 !important;
		}
	`;

	export const DayOffCalculationMobileRow = styled.div`
		display: flex;
		flex-direction: column;
	`;

	export const DayOffCalculationMobileRowTitle = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const DayOffCalculationMobileRowDate = styled.div`
		font-size: 16px;
	`;

	export const DayOffCalculationMobileRowDescription = styled.div`
		color: ${COLORS.black};
		text-align: right;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
	`;

	export const DayOffCalculationMobileRowDateInput = styled.div`
		padding: 8px 16px;
		border-radius: 4px;
		background: ${COLORS.lightGray2};
		margin-top: 16px;
		label:not(.ant-radio-wrapper) {
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			margin-bottom: -10px;
		}
	`;

	export const DayOffCalculationMobileFooter = styled.div`
		border-top: 1px solid ${COLORS.gray};
		margin-top: 16px;
		padding-top: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const DayOffCalculationMobileFooterTitle = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;

	export const DayOffCalculationMobileFooterCalculation = styled.div`
		color: ${COLORS.black};
		text-align: right;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
	`;

	export const Select = styled(SelectAntd)`
		margin-bottom: 24px;
	`;

	export const DateRangeLine = styled.div``;

	export const DateRangeLineTitle = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 8px;
		span {
			display: inline-block;
			color: ${COLORS.red};
			padding: 0 4px;
		}
	`;

	export const DateRangeLineMainContent = styled.div`
		overflow: hidden;
	`;

	export const DateRangeInput = styled.div<DateRangeInputProps>`
		text-align: center;
		${(props) =>
		!props.isMobile &&
			`
				border-radius: 4px;
				background: ${COLORS.lightGray3};
				padding: 16px 16px 0 16px;
				`}
		
		.ant-picker-panels {
			@media ${device.mobile} {
				flex-direction: column !important;
			}
		}
		.ant-picker-range {
			border: none;
			box-shadow: none !important;
			padding: 0;
			${(props) =>
				!props.isMobile &&
				`
				background: ${COLORS.lightGray3};
				`}
			.ant-picker-suffix {
				display: none;
			}
			> .ant-picker-input {
				width: ${(props) => (props.isMobile ? '50%' : '43%')};
				box-sizing: border-box;
				margin: 0;
				padding: 4px 11px 4px;
				color: rgba(0, 0, 0, 0.88);
				font-size: 14px;
				line-height: 1;
				list-style: none;
				font-family: Inter;
				position: relative;
				display: inline-flex;
				align-items: center;
				background: ${COLORS.white};
				border: 1px solid ${COLORS.neutral5};
				border-radius: 6px;
				transition: border 0.2s, box-shadow 0.2s;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 10px;
					width: 16px;
					height: 16px;
					background: url('/images/calendarIcon.svg') no-repeat;
					background-size: contain;
					transform: translateY(-50%);
				}
			}
			&:hover,
			&:focus {
				> .ant-picker-input {
					border-color: ${COLORS.inputColor};
					box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
					border-inline-end-width: 1px;
					outline: 0;
				}
			}
			.ant-picker-range-separator {
				position: relative;
				width: ${(props) => (props.isMobile ? '10%' : '16%')};
				text-align: center;
				.ant-picker-separator {
					display: none;
				}
				&::before {
					content: '-';
					${(props) =>
					!props.isMobile &&
						`
					position: absolute;
					top: 50%;
					left: 50%;
					width: 40px;
					height: 400px;
					background: ${COLORS.white};
					transform: translate(-50%, -50%);
				`}
				}
				+ .ant-picker-input {
					position: relative;
					left: -10px;
					@media ${device.tablet} {
						position: static;
					}
				}
			}
			.ant-picker-active-bar {
				display: none;
			}
			.ant-picker-clear {
				left: 93%;
				@media ${device.tablet} {
					left: auto;
				}
			}
		}
	`;

	export const DateRangeLineContent = styled.div`
		display: flex;
		gap: 16px;
		align-items: center;
		@media ${device.mobile} {
			gap: 4px;
		}
	`;

	export const DateRangeLineContentColumn = styled.div`
		width: 100%;
	`;

	export const DateRangeLineContentColumnsWrap = styled.div`
		display: flex;
		& > div:first-child {
			width: calc(50% + 20px);
		}
		@media ${device.tablet} {
			display: none;
		}
	`;
	export const DateRangeLineContentColumnTitle = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		margin-bottom: 8px;
	`;

	export const DateRangeLineContentColumnContent = styled.div`
		border-radius: 4px;
		background: ${COLORS.lightGray3};
		padding: 0 16px 16px 16px;
		@media ${device.mobile} {
			padding: 0 6px 16px 6px;
		}
	`;

	export const RadioGroup = styled(Group)`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		margin-top: 16px;
		label {
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			@media ${device.mobile} {
				font-size: 13px;
			}
		}
	`;

	export const RadioOption = styled(Radio)`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;

	export const RadioFields = styled.div`
		display: flex;
		width: 100%;
		gap: 32px;
	`;

	export const Dragger = styled(DraggerAntd)`
		&.ant-upload-wrapper {
			.ant-upload-list {
				.ant-upload-list-item {
					border: 1px solid ${COLORS.gray};
					padding: 10px;
					height: auto;
					transition: all 0.2s ease;
					&:hover {
						background-color: transparent;
						box-shadow: 0px 3px 5px 0px ${COLORS.gray};
						cursor: pointer;
					}
					.ant-upload-list-item-actions {
						display: flex;
					}
					.ant-upload-icon {
						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-actions {
						.ant-btn {
							margin: 0 5px;
						}
					}
					.ant-upload-list-item-action {
						opacity: 1;
						color: ${COLORS.black};

						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-name {
						color: ${COLORS.black};
						font-size: 16px;
					}
				}
			}
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		margin-top: 26px;
		gap: 16px;
		justify-content: end;
		button {
			width: auto;
		}
		@media ${device.mobile} {
			flex-direction: column-reverse;
			button:first-child {
				border-radius: 4px;
				border: 1px solid ${COLORS.darkGray2};
				color: ${COLORS.darkGray2};
			}
		}
	`;
}
