import { isEmpty } from 'lodash';

import { get, post, put, del } from 'api/axiosInstance';
import { RequestsEnum } from 'types';
import { qs } from 'utils/helpers';

export const getAllTimeOffRequests = async (params) => {
	const queryParams = !isEmpty(params) ? qs(params) : '';

	return get(`${RequestsEnum.HRTimeOffRequest}${queryParams}`);
};

export const getTimeOffRequestDuration = async (values) => {
	return post(RequestsEnum.HRTimeOffRequestDuration, values);
};

export const createTimeOffRequest = async (values) => post(RequestsEnum.HRTimeOffRequest, values);

export const updateTimeOffRequest = async ({ requestId, ...values }) =>
	put(`${RequestsEnum.HRTimeOffRequest}/${requestId}`, values);

export const approveTimeOffRequest = async (requestId) =>
	post(`${RequestsEnum.HRTimeOffRequest}/${requestId}/approve`);

export const declineTimeOffRequest = async ({ requestId, comment }) =>
	post(`${RequestsEnum.HRTimeOffRequest}/${requestId}/decline`, { comment });

export const approveAllTimeOffRequests = async () =>
	post(`${RequestsEnum.HRTimeOffRequest}${RequestsEnum.ApproveAll}`);

export const getTimeOffStaticsPerEmployee = async ({ employeeId, onlyApproved, year }) => {
	const yearParam = year ? `&year=${year}` : '';

	return get(
		`${RequestsEnum.HRTimeOffRequestStatics}/${employeeId}?onlyApproved=${onlyApproved}${yearParam}`,
	);
};

export const getTimeOffStaticsCompanyWide = async (onlyApproved) =>
	get(`${RequestsEnum.HRTimeOffRequestStatics}?onlyApproved=${onlyApproved}`);

export const getBankHolidays = async (params) => {
	const queryParams = !isEmpty(params) ? qs(params) : '';

	return get(`${RequestsEnum.HRSettingsHoliday}${queryParams}`);
};

export const deleteTimeOffRequest = async (requestId) =>
	del(`${RequestsEnum.HRTimeOffRequest}/${requestId}`);

export const createCustomHoliday = async (values) => post(RequestsEnum.HRSettingsHoliday, values);

export const updateCustomHoliday = async ({ id, ...values }) =>
	put(`${RequestsEnum.HRSettingsHoliday}/${id}`, values);

export const deleteCustomHoliday = async (id) => del(`${RequestsEnum.HRSettingsHoliday}/${id}`);

export const getTimeOffRequestsPending = async () => get(RequestsEnum.HRTimeOffRequestPending);

export const getTimeOffRequestsForEmployeeById = async (employeeId) =>
	get(`${RequestsEnum.HRTimeOffRequestEmployee}/${employeeId}`);

const timeOffRequestsService = {
	getAllTimeOffRequests,
	getTimeOffRequestDuration,
	createTimeOffRequest,
	updateTimeOffRequest,
	approveTimeOffRequest,
	declineTimeOffRequest,
	approveAllTimeOffRequests,
	getTimeOffStaticsPerEmployee,
	getBankHolidays,
	deleteTimeOffRequest,
	createCustomHoliday,
	updateCustomHoliday,
	deleteCustomHoliday,
	getTimeOffRequestsPending,
	getTimeOffRequestsForEmployeeById,
	getTimeOffStaticsCompanyWide,
};

export default timeOffRequestsService;
