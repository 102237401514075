import React, { useState } from 'react';
import { connect } from 'react-redux';

import useMedia from 'use-media';

import { ButtonTypes } from 'components/Button/Button.types';
import { IUser } from 'modules/Common/types';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import HRDashboard from 'modules/HR/containers/HRDashboard';
import { hrDucks } from 'modules/HR/ducks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesEnum } from 'types';

import { Styled } from './DashboardPage.styled';

type DashboardPageProps = {
	userInfo: IUser;
};

const DashboardPage = ({ userInfo }: DashboardPageProps) => {
	const [isRequestModalOpen, setIsRequestModalOpen] = useState<number | null>(null);
	const isSuperUser = [UserRolesEnum.HR_MANAGER, UserRolesEnum.JAT_SUPER_USER].includes(
		userInfo?.roleId,
	);
	const employeeId = userInfo?.employee;
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	const handleOpenModal = (requestId?: number) => {
		const id = requestId || employeeId;
		id && setIsRequestModalOpen(id);
	};

	const handleClosedModal = () => {
		setIsRequestModalOpen(null);
	};

	return (
		<Styled.Root isMobile={isMobile}>
			{!isMobile && (
				<Styled.Head>
					<Styled.Title>Dashboard</Styled.Title>
					<HRThemedButton
						buttonType={ButtonTypes.primary}
						type='button'
						onClick={() => handleOpenModal()}
						style={{ zIndex: 10 }}
					>
						Request Time Off
					</HRThemedButton>
				</Styled.Head>
			)}
			<HRDashboard
				isSuperUser={isSuperUser}
				employeeId={userInfo.employee}
				isRequestModalOpen={isRequestModalOpen}
				handleOpenRequestModal={handleOpenModal}
				handleCloseRequestModal={handleClosedModal}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
		employeeOptions: hrDucks.hrSelectors.getEmployeeOptions(state),
	}),
	{
		getTimeOffStaticsPerEmployeeRequested: hrDucks.hrActions.getTimeOffStaticsPerEmployeeRequested,
	},
)(DashboardPage);
