import { createSelector } from 'reselect';

export const createSelectors = (root: string) => {
	const hrModuleRootState = createSelector([(state) => state[root]], (state) => state.data);
	const getHrModuleLoading = createSelector(
		[(state) => state[root]],
		(state) => state.loadingReducer,
	);

	const getEmployeeStatuses = createSelector(hrModuleRootState, (data) => data.employeeStatuses);

	const getEmployeesList = createSelector(hrModuleRootState, (data) => data.employeesList);

	const getEmployeeDetails = createSelector(hrModuleRootState, (data) => data.employeeDetails);

	const getEmployeeShortList = createSelector(hrModuleRootState, (data) => data.employeeShortList);

	const getOnOffBoardingSettings = createSelector(
		hrModuleRootState,
		(data) => data.onOffBoardingSettings,
	);

	const getEmployeeAttachments = createSelector(
		hrModuleRootState,
		(data) => data.employeeAttachments,
	);

	const getVacationSettings = createSelector(hrModuleRootState, (data) => data.vacationSettings);

	const getEmployeeOptions = createSelector(hrModuleRootState, (data) =>
		data.employeeOptions.map((employee: { id: number; fullName: string }) => ({
			value: employee.id,
			label: employee.fullName,
		})),
	);

	const getWorkWeekSettings = createSelector(hrModuleRootState, (data) => data.workWeekSettings);

	const getTimeOffRequestDuration = createSelector(
		hrModuleRootState,
		(data) => data.timeOffRequestDuration,
	);
	const getTimeOffStaticsPerEmployee = createSelector(
		hrModuleRootState,
		(data) => data.timeOffStaticsCurrentEmployee,
	);

	const getAllTimeOffRequests = createSelector(
		hrModuleRootState,
		(data) => data.requestTimeOffCalendarData,
	);

	const getBankHolidays = createSelector(hrModuleRootState, (data) => data.bankHolidays);

	const getPendingTimeOffRequests = createSelector(
		hrModuleRootState,
		(data) => data.pendingTimeOffRequests,
	);

	const getCurrentEmployeeTimeOffRequests = createSelector(
		hrModuleRootState,
		(data) => data.currentEmployeeTimeOffList,
	);

	const getTimeOffStaticsCompanyWide = createSelector(
		hrModuleRootState,
		(data) => data.timeOffStaticsCompanyWide,
	);

	return {
		getHrModuleLoading,
		getEmployeeStatuses,
		getEmployeesList,
		getEmployeeDetails,
		getEmployeeShortList,
		getEmployeeAttachments,
		getOnOffBoardingSettings,
		getVacationSettings,
		getEmployeeOptions,
		getWorkWeekSettings,
		getTimeOffRequestDuration,
		getTimeOffStaticsPerEmployee,
		getAllTimeOffRequests,
		getBankHolidays,
		getPendingTimeOffRequests,
		getCurrentEmployeeTimeOffRequests,
		getTimeOffStaticsCompanyWide,
	};
};
