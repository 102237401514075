import React, { FC } from 'react';
import { useMedia } from 'react-use';

import { IEmployeeShortValues } from 'modules/Common/types/employee';
import { RequestTimeOffType, TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import HRViewEmployeesDashboard from 'modules/HR/components/HRViewEmployeesDashboard';
import TimeOffUsedStatistics from 'modules/HR/components/TimeOffUsedStatistics';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { GenericType } from 'types';

import HRCalendarWeekly from '../../HRCalendarWeekly';
import { Styled } from '../HRDashboard.styled';
import RequestsWidget from '../RequestsWidget/RequestsWidget';

interface HRViewProps {
	showRequestTab: boolean;
	showCalendarTab: boolean;
	currentCalendarDate: Date;
	employeeData: IEmployeeShortValues[];
	pendingTimeOffRequests: RequestTimeOffType[];
	timeOffStaticsCompanyWide: TimeOffStaticsType[];
	employeeId?: number;
	isRequestModalOpen: number | null;
	loading: GenericType;
	handleApproveTimeOffRequest: (requestId: number) => void;
	handleDeclineTimeOffRequest: (
		values: { requestId: number; comment?: string },
		cb?: () => void,
	) => void;
	handleApproveAllRequests: () => void;
	handleOpenRequestModal: (requestId?: number) => void;
	handleCloseRequestModal: () => void;
	handleUpdateCalendarDate: (date: Date) => void;
}

const HRView: FC<HRViewProps> = ({
	isRequestModalOpen,
	currentCalendarDate,
	showRequestTab,
	showCalendarTab,
	employeeId,
	employeeData,
	pendingTimeOffRequests,
	timeOffStaticsCompanyWide,
	loading,
	handleApproveAllRequests,
	handleApproveTimeOffRequest,
	handleDeclineTimeOffRequest,
	handleOpenRequestModal,
	handleCloseRequestModal,
	handleUpdateCalendarDate,
}) => {
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	return (
		<Styled.DashboardLayout>
			<Styled.LayoutColumns isMobile={isMobile}>
				<Styled.LeftColumn isMobile={isMobile}>
					<HRCalendarWeekly
						isRequestModalOpen={isRequestModalOpen}
						handleEditRequestModalOpen={handleOpenRequestModal}
						handleCloseRequestModal={handleCloseRequestModal}
						showCalendarTab={showCalendarTab}
						currentDate={currentCalendarDate}
						handleUpdateCurrentDate={handleUpdateCalendarDate}
					/>
					{showRequestTab && (
						<RequestsWidget
							title='Requests'
							emptyRequestsTitle='No requests at the moment'
							isEmployeeView={false}
							loading={loading}
							employeeRequests={pendingTimeOffRequests}
							handleApproveAllRequests={handleApproveAllRequests}
							handleApproveTimeOffRequest={handleApproveTimeOffRequest}
							handleDeclineTimeOffRequest={handleDeclineTimeOffRequest}
						/>
					)}
				</Styled.LeftColumn>
				<Styled.RightColumn isMobile={isMobile}>
					<TimeOffUsedStatistics
						statisticData={timeOffStaticsCompanyWide}
						dashboardChart={true}
						employeeId={employeeId}
						loading={loading}
					/>
					<HRViewEmployeesDashboard
						employeeList={employeeData}
						loading={!!loading?.getEmployeeShortListLoad}
					/>
				</Styled.RightColumn>
			</Styled.LayoutColumns>
		</Styled.DashboardLayout>
	);
};

export default HRView;
