import React, { type FC } from 'react';

import Spinner from 'components/Spinner';
import { TimeOffStaticsType } from 'modules/Common/types/hrModuleTypes';
import { GenericType } from 'types';

import TimeOffPieChart from '../TimeOffPieChart';

import { Styled } from './TimeOffUsedStatisticsEmployee.styled';
import { TimeOffs, TimeOffTypes } from './TimeOffUsedStatisticsEmployee.types';

type TimeOffUsedStatisticsProps = {
	statisticData: TimeOffStaticsType[];
	loading: GenericType;
};

const TimeOffUsedStatisticsEmployee: FC<TimeOffUsedStatisticsProps> = ({
	statisticData,
	loading,
}) => {
	const vacationStatistic = statisticData.find(
		(stat) => stat.timeOffType === TimeOffTypes.VACATION,
	);

	const { usedDays = 0, totalDays = 0 } = vacationStatistic || {};

	const percentUsed = totalDays === 0 ? 0 : Math.round((usedDays / totalDays) * 100);
	const isLoading = !!loading?.getTimeOffStaticsPerEmployeeLoad;

	return (
		<Styled.Root>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<Styled.Title>Time Offs Used This Year</Styled.Title>
					<Styled.PieWrap>
						<TimeOffPieChart
							offsetPercentage={0}
							color={TimeOffs[TimeOffTypes.VACATION].color}
							percentUsed={percentUsed}
							width='174px'
							borderWidth='24px'
							fontSize='32px'
						/>
					</Styled.PieWrap>
					<Styled.StatisticsDataWrap>
						<Styled.StatisticsDataEmoji>
							{TimeOffs[TimeOffTypes.VACATION].emoji}
						</Styled.StatisticsDataEmoji>
						<Styled.StatisticsName>Vacation</Styled.StatisticsName>
						<Styled.StatisticsData>{`${usedDays}/${totalDays} days`}</Styled.StatisticsData>
					</Styled.StatisticsDataWrap>
				</>
			)}
		</Styled.Root>
	);
};

export default TimeOffUsedStatisticsEmployee;
