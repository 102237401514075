interface TimeOffStatistic {
	timeOffType: string;
	usedDays: number;
	totalDays: number | null;
}

export const combineTimeOffStatistics = (results: TimeOffStatistic[][]): TimeOffStatistic[] => {
	return results.flat().reduce((acc: TimeOffStatistic[], curr) => {
		const existing = acc.find((item) => item.timeOffType === curr.timeOffType);

		if (existing) {
			existing.usedDays = +(existing.usedDays + curr.usedDays).toFixed(2);
			// Only sum totalDays if both values are not null
			if (existing.totalDays !== null && curr.totalDays !== null) {
				existing.totalDays = +(existing.totalDays + curr.totalDays).toFixed(2);
			}
		} else {
			acc.push({ ...curr });
		}

		return acc;
	}, []);
};
