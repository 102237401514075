import React, { useState, type FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { Tabs } from 'antd';
import dayjs from 'dayjs';

import Table from 'components/Table';
import { useMount } from 'hooks';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import { InvoicesStatusTypesFormEnum } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

import { InvoiceStatusModal } from '../InvoiceStatusModal/InvoiceStatusModal';

import { columns as contractsCol } from './ContractsReports.entities';
import {
	contractStatuses,
	getDateRangeFromPeriod,
	invoicePaymentMethods,
	invoiceStatuses,
	periodOptions,
	statusOptionsAll,
	statusOptionsContracts,
	statusOptionsInvoices,
} from './FinancialReports.constants';
import { columns as financeCol } from './FinancialReports.entities';
import { handleTableChange, handleTablePaginationChange } from './FinancialReports.helpers';
import { Styled } from './FinancialReports.styled';
import { FinancialReportsProps, TabsTitleEnum } from './FinancialReports.types';
import { columns as invoicesCol } from './InvoicesReports.entities';

const FinancialReports: FC<FinancialReportsProps> = ({
	financialReports,
	invoicesReports,
	contractsReports,
	roles,
	userList,
	loading,
	getFinancialReportRequested,
	getInvoicesReportRequested,
	getContractsReportRequested,
	updateContractStatus,
	updateInvoiceStatusRequested,
	getBOUserListRequested,
}) => {
	const [selectedInvoiceId, setSelectedInvoiceId] = useState<null | number>(null);
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.All);
	const [financeTableParams, setFinanceTableParams] = useState({});
	const [contractTableParams, setContractTableParams] = useState({});
	const [invoiceTableParams, setInvoiceTableParams] = useState({});

	useMount(() => {
		getFinancialReportRequested({ page: DEFAULT_CURRENT_PAGE, size: DEFAULT_PAGE_SIZE });
		userList.length === 0 && getBOUserListRequested();
	});

	const { pageIndex, pageSize, totalCount, data: financialData } = financialReports || {};
	const {
		pageIndex: invoicePageIndex = DEFAULT_CURRENT_PAGE,
		pageSize: invoicePageSize = DEFAULT_PAGE_SIZE,
		totalCount: invoiceTotalCount,
		data: invoicesData,
	} = invoicesReports || {};
	const {
		pageIndex: contractsPageIndex = DEFAULT_CURRENT_PAGE,
		pageSize: contractsPageSize = DEFAULT_PAGE_SIZE,
		totalCount: contractsTotalCount,
		data: contractsData,
	} = contractsReports || {};

	const updateReportsData = useCallback(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		const reportRequests = {
			[TabsTitleEnum.All]: getFinancialReportRequested,
			[TabsTitleEnum.Invoices]: getInvoicesReportRequested,
			[TabsTitleEnum.Contracts]: getContractsReportRequested,
		};

		const tableParams = {
			[TabsTitleEnum.All]: financeTableParams,
			[TabsTitleEnum.Invoices]: invoiceTableParams,
			[TabsTitleEnum.Contracts]: contractTableParams,
		};

		reportRequests[tab]?.({
			page: tableParams[tab]?.page || 0,
			size: tableParams[tab]?.size || DEFAULT_PAGE_SIZE,
			...(tableParams[tab]?.search && { search: tableParams[tab]?.search }),
			...(tableParams[tab]?.state && {
				[tab === TabsTitleEnum.All ? 'status' : 'state']: tableParams[tab]?.state,
			}),
			...(tableParams[tab]?.employee &&
				(tableParams[tab]?.employee === 'isSystem' && tab !== TabsTitleEnum.All
					? { isSystem: true }
					: { employee: tableParams[tab]?.employee })),
			...(tableParams[tab]?.fromDate &&
				tableParams[tab]?.toDate && {
				fromDate: tableParams[tab]?.fromDate,
				toDate: tableParams[tab]?.toDate,
			}),
			...(tableParams[tab]?.sorter?.order &&
				tableParams[tab]?.sorter?.field && {
				sort: `${tableParams[tab]?.sorter?.field},${
					ordersMap[tableParams[tab]?.sorter?.order || 'ascend']
				}`,
			}),
		});
	}, [tab, financeTableParams, invoiceTableParams, contractTableParams]);

	useEffect(() => {
		updateReportsData();
	}, [tab, updateReportsData]);

	const handleChangeTabs = (id: string) => {
		if (tab === TabsTitleEnum.All) {
			setFinanceTableParams({});
		} else if (tab === TabsTitleEnum.Invoices) {
			setInvoiceTableParams({});
		} else if (tab === TabsTitleEnum.Contracts) {
			setContractTableParams({});
		}
		setTab(id);
	};

	const handleChangeContractStatus = useCallback(
		(contractId: number, statusId: number) => {
			updateContractStatus(
				contractId,
				{ contractState: statusId },
				{ page: pageIndex as number, size: pageSize as number, client: '' },
				undefined,
				() =>
					setContractTableParams((prevParams) => ({
						...prevParams,
					})),
			);
		},
		[contractTableParams],
	);

	const handleChangeInvoiceStatus = useCallback(
		async (invoiceId: number, statusId: number) => {
			if (InvoicesStatusTypesFormEnum.PaidManually === statusId) {
				handleStatusModalOpen(invoiceId);
			} else {
				updateInvoiceStatusRequested(invoiceId, { status: statusId }, () => updateReportsData());
			}
		},
		[financeTableParams, invoiceTableParams, tab],
	);

	const handleStatusModalOpen = useCallback((invoiceId: number) => {
		setSelectedInvoiceId(invoiceId);
	}, []);

	const handleStatusModalCancel = () => setSelectedInvoiceId(null);

	const refreshInvoicesResults = useCallback(async () => {
		updateReportsData();
	}, [updateReportsData]);

	const handleSearch = (value: string) => {
		const tableParams = {
			[TabsTitleEnum.All]: setFinanceTableParams,
			[TabsTitleEnum.Invoices]: setInvoiceTableParams,
			[TabsTitleEnum.Contracts]: setContractTableParams,
		};

		tableParams[tab]?.((prevParams) => ({ ...prevParams, page: 0, search: value }));
	};

	const handleStatusFilter = (value: number) => {
		const tableParams = {
			[TabsTitleEnum.All]: setFinanceTableParams,
			[TabsTitleEnum.Invoices]: setInvoiceTableParams,
			[TabsTitleEnum.Contracts]: setContractTableParams,
		};

		tableParams[tab]?.((prevParams) => ({ ...prevParams, page: 0, state: value }));
	};

	const handlePeriodFilter = (value: number) => {
		const dateRange = getDateRangeFromPeriod(value);
		setFinanceTableParams({
			...financeTableParams,
			page: 0,
			...{ fromDate: dateRange?.fromDate, toDate: dateRange?.toDate },
		});
	};

	const onDateFilter = (value) => {
		const tableParams = {
			[TabsTitleEnum.Invoices]: setInvoiceTableParams,
			[TabsTitleEnum.Contracts]: setContractTableParams,
		};
		if (value) {
			const fromDate = dayjs(value[0]).format('YYYY-MM-DD');
			const toDate = dayjs(value[1]).format('YYYY-MM-DD');

			tableParams[tab]?.((prevParams) => ({ ...prevParams, page: 0, fromDate, toDate }));
		} else {
			tableParams[tab]?.((prevParams) => ({
				...prevParams,
				page: 0,
				fromDate: null,
				toDate: null,
			}));
		}
	};

	const handleEmployeeFilter = (value, option) => {
		const tableParams = {
			[TabsTitleEnum.All]: setFinanceTableParams,
			[TabsTitleEnum.Invoices]: setInvoiceTableParams,
			[TabsTitleEnum.Contracts]: setContractTableParams,
		};
		const valueToSet = tab === TabsTitleEnum.All ? option.label : value;

		tableParams[tab]?.((prevParams) => ({ ...prevParams, page: 0, employee: valueToSet }));
	};

	const financeColumns = financeCol({
		roles,
		invoiceStatuses,
		contractStatuses,
		handleChangeInvoiceStatus,
		handleChangeContractStatus,
	});
	const invoicesColumns = invoicesCol({
		invoiceStatuses,
		handleChangeInvoiceStatus,
	});
	const contractsColumns = contractsCol({
		roles,
		contractStatuses,
		handleChangeContractStatus,
	});

	return (
		<>
			<Tabs
				defaultActiveKey={TabsTitleEnum.All}
				onChange={handleChangeTabs}
				items={[
					{
						label: 'ALL',
						key: TabsTitleEnum.All,
						active: true,
						children: (
							<Styled.FinanceTableWrap>
								<Table
									pageSize={pageSize}
									current={pageIndex}
									loading={loading?.getTicketsLoad}
									total={totalCount}
									data={financialData}
									columns={financeColumns}
									onChange={handleTablePaginationChange(setFinanceTableParams, financeTableParams)}
									onPageSizeChange={handleTableChange(setFinanceTableParams, financeTableParams)}
									onTableChange={handleTableChange(setFinanceTableParams, financeTableParams)}
								/>
							</Styled.FinanceTableWrap>
						),
					},
					{
						label: 'INVOICES',
						key: TabsTitleEnum.Invoices,
						active: true,
						children: (
							<Styled.GenericTableWrap>
								<Table
									pageSize={invoicePageSize}
									current={invoicePageIndex}
									loading={!!loading?.getInvoicesReportLoad}
									total={invoiceTotalCount}
									data={invoicesData}
									columns={invoicesColumns}
									onChange={handleTablePaginationChange(setInvoiceTableParams, invoiceTableParams)}
									onPageSizeChange={handleTablePaginationChange(
										setInvoiceTableParams,
										invoiceTableParams,
									)}
									onTableChange={handleTableChange(setInvoiceTableParams, invoiceTableParams)}
								/>
							</Styled.GenericTableWrap>
						),
					},
					{
						label: 'CONTRACTS',
						key: TabsTitleEnum.Contracts,
						active: true,
						children: (
							<Styled.GenericTableWrap>
								<Table
									pageSize={contractsPageSize}
									current={contractsPageIndex}
									loading={!!loading?.getContractsReportLoad}
									total={contractsTotalCount}
									data={contractsData}
									columns={contractsColumns}
									onChange={handleTablePaginationChange(
										setContractTableParams,
										contractTableParams,
									)}
									onPageSizeChange={handleTablePaginationChange(
										setContractTableParams,
										contractTableParams,
									)}
									onTableChange={handleTableChange(setContractTableParams, contractTableParams)}
								/>
							</Styled.GenericTableWrap>
						),
					},
				]}
				tabBarExtraContent={
					<SearchFilterSortWrapper
						key={tab}
						search={{
							onSearch: handleSearch,
							isDebounce: true,
							placeholder: 'Search',
							styles: { width: '140px', flexBasis: 'auto' },
						}}
						filter={{
							mode: 'single',
							options:
								tab === TabsTitleEnum.All
									? statusOptionsAll
									: tab === TabsTitleEnum.Invoices
										? statusOptionsInvoices
										: statusOptionsContracts,
							onFilter: handleStatusFilter,
							label: 'Status',
							width: '140px',
							placeholder: 'All',
						}}
						secondFilter={
							tab === TabsTitleEnum.All && {
								mode: 'single',
								options: periodOptions,
								onFilter: handlePeriodFilter,
								width: '140px',
								label: 'Period',
								placeholder: 'All',
							}
						}
						thirdFilter={{
							mode: 'single',
							options:
								tab === TabsTitleEnum.Contracts
									? userList
									: [{ label: 'Connect', value: 'isSystem' }, ...userList],
							onFilter: handleEmployeeFilter,
							width: '140px',
							label: 'Employee',
							placeholder: 'All',
							showSearch: true,
						}}
						dateFilter={
							tab !== TabsTitleEnum.All && {
								label: 'Date Period',
								placeholder: 'Select date period',
								onDateFilter,
							}
						}
					/>
				}
			/>
			<InvoiceStatusModal
				invoiceId={selectedInvoiceId}
				onCancel={handleStatusModalCancel}
				invoicePaymentMethods={invoicePaymentMethods}
				updateInvoiceStatusRequested={updateInvoiceStatusRequested}
				updateInvoiceCallback={refreshInvoicesResults}
				loading={!!loading?.updateInvoiceStatusLoad}
				rootClassName='invoice-status-modal'
			/>
		</>
	);
};

export default connect(
	(state) => ({
		financialReports: backOfficeDucks.backOfficeSelectors.getFinancialReportsState(state),
		invoicesReports: backOfficeDucks.backOfficeSelectors.getInvoicesReportsState(state),
		contractsReports: backOfficeDucks.backOfficeSelectors.getContractsReportsState(state),
		userList: backOfficeDucks.backOfficeSelectors.getBOUserList(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getFinancialReportRequested: backOfficeDucks.backOfficeActions.getFinancialReportRequested,
		getInvoicesReportRequested: backOfficeDucks.backOfficeActions.getInvoicesReportRequested,
		getContractsReportRequested: backOfficeDucks.backOfficeActions.getContractsReportRequested,
		updateContractStatus: commonDucks.commonActions.updateContractStatusRequested,
		updateInvoiceStatusRequested: backOfficeDucks.backOfficeActions.updateInvoiceStatusRequested,
		getBOUserListRequested: backOfficeDucks.backOfficeActions.getBOUserListRequested,
	},
)(FinancialReports);
