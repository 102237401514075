import { IPasswordFields } from 'modules/Common/types';
import { IEmployeeValues, IEmployeeValuesResponse } from 'modules/Common/types/employee';
import {
	BankHolidayBaseType,
	BankHolidayType,
	OnOffBoardingSettingType,
} from 'modules/Common/types/hrModuleTypes';

import {
	CreateTimeOffRequestType,
	GetTimeOffRequestDurationType,
	UpdateTimeOffRequestType,
} from '../components/HRRequestEditTimeOffModal/HRRequestEditTimeOffModal.types';
import { WorkWeekSettingsValuesType } from '../containers/HRModuleSettings/HRModuleSettings.types';

import { ActionTypes } from './types';

export const createActions = (TYPES: ActionTypes) => {
	const getEmployeeStatusesRequested = () => ({
		type: TYPES.GET_EMPLOYEE_STATUSES.REQUESTED,
	});
	const createEmployeeRequested = (data: IEmployeeValues, callback?: () => void) => ({
		type: TYPES.CREATE_EMPLOYEE.REQUESTED,
		payload: data,
		callback,
	});
	const geEmployeesRequested = (params, isSuperUser = false) => ({
		type: TYPES.VIEW_EMPLOYEES_LIST.REQUESTED,
		payload: params,
		isSuperUser,
	});
	const archiveEmployeeRequested = (
		data: { employeeId: number; leaveDate: string },
		callback?: () => void,
	) => ({
		type: TYPES.ARCHIVE_EMPLOYEE.REQUESTED,
		payload: data,
		callback,
	});
	const unArchiveEmployeeRequested = (employeeId: number, callback?: () => void) => ({
		type: TYPES.UNARCHIVE_EMPLOYEE.REQUESTED,
		payload: employeeId,
		callback,
	});
	const changeEmployeeStatusRequested = (
		data: { employeeId: number; employeeStatus: number; startDate?: string; leaveDate?: string },
		callback?: () => void,
	) => ({
		type: TYPES.CHANGE_EMPLOYEE_STATUS.REQUESTED,
		payload: data,
		callback,
	});
	const sendFirstDayInvitationRequested = (employeeId: string) => ({
		type: TYPES.SEND_FIRST_DAY_INVITATION.REQUESTED,
		payload: employeeId,
	});
	const getEmployeeByIdRequested = (employeeId: string, isSuperUser: boolean) => ({
		type: TYPES.GET_EMPLOYEE_BY_ID.REQUESTED,
		payload: employeeId,
		isSuperUser,
	});
	const getEmployeeAttachmentsRequested = (employeeId: string, isSuperUser: boolean) => ({
		type: TYPES.GET_EMPLOYEE_ATTACHMENTS.REQUESTED,
		payload: { employeeId, isSuperUser },
	});
	const saveEmployeeAttachmentsRequested = (
		attachments: string[],
		employeeId: string,
		isSuperUser: boolean,
		callback?: () => void,
	) => ({
		type: TYPES.SAVE_EMPLOYEE_ATTACHMENTS.REQUESTED,
		payload: { attachments, employeeId, isSuperUser },
		callback,
	});
	const getOwnProfileRequested = () => ({
		type: TYPES.GET_OWN_PROFILE.REQUESTED,
	});
	const getOnOffBoardingSettingsRequested = () => ({
		type: TYPES.GET_ONOFF_BOARDING_SETTINGS.REQUESTED,
	});
	const setOnOffBoardingSettingsRequested = (values: OnOffBoardingSettingType) => ({
		type: TYPES.SET_ONOFF_BOARDING_SETTINGS.REQUESTED,
		payload: values,
	});
	const editOwnProfileRequested = (
		isSuperUser: boolean,
		values: IEmployeeValues,
		callback?: (result: IEmployeeValuesResponse) => void,
	) => ({
		type: TYPES.EDIT_OWN_PROFILE.REQUESTED,
		payload: { isSuperUser, values },
		callback,
	});
	const editOwnPasswordRequested = (isSuperUser: boolean, values: IPasswordFields) => ({
		type: TYPES.EDIT_OWN_PASSWORD.REQUESTED,
		payload: { isSuperUser, values },
	});
	const editEmployeeRequested = (
		isSuperUser: boolean,
		employeeId: string,
		values: IEmployeeValues,
		callback?: () => void,
	) => ({
		type: TYPES.EDIT_EMPLOYEE.REQUESTED,
		payload: { isSuperUser, employeeId, values },
		callback,
	});
	const editEmployeePasswordRequested = (
		isSuperUser: boolean,
		employeeId: string,
		values: IEmployeeValues,
	) => ({
		type: TYPES.EDIT_EMPLOYEE_PASSWORD.REQUESTED,
		payload: { isSuperUser, employeeId, values },
	});
	const getEmployeeShortListRequested = () => ({
		type: TYPES.GET_EMPLOYEE_SHORT_LIST.REQUESTED,
	});
	const resetEmployeeDetails = () => ({
		type: TYPES.RESET_EMPLOYEE_DETAILS,
	});
	const getHRVacationSettingsRequested = () => ({
		type: TYPES.GET_HR_VACATION_SETTINGS.REQUESTED,
	});
	const createUpdateVacationSettingsRequested = (
		data: { employee: number | null; days: number },
		callback?: (error?: string) => void,
	) => ({
		type: TYPES.CREATE_UPDATE_VACATION_SETTINGS.REQUESTED,
		payload: data,
		callback,
	});
	const deleteVacationSettingsRequested = (id: number, callback?: () => void) => ({
		type: TYPES.DELETE_VACATION_SETTINGS.REQUESTED,
		payload: id,
		callback,
	});
	const getEmployeeOptionsRequested = () => ({
		type: TYPES.GET_EMPLOYEE_OPTIONS.REQUESTED,
	});
	const getWorkWeekSettingsRequested = () => ({
		type: TYPES.GET_WORK_WEEK_SETTINGS.REQUESTED,
	});
	const updateWorkWeekSettingsRequested = (data: WorkWeekSettingsValuesType) => ({
		type: TYPES.UPDATE_WORK_WEEK_SETTINGS.REQUESTED,
		payload: data,
	});
	const getTimeOffRequestDurationRequested = (data: GetTimeOffRequestDurationType) => ({
		type: TYPES.GET_TIME_OFF_REQUEST_DURATION.REQUESTED,
		payload: data,
	});
	const resetTimeOffRequestDuration = () => ({
		type: TYPES.RESET_TIME_OFF_REQUEST_DURATION,
	});
	const createTimeOffRequestRequested = (data: CreateTimeOffRequestType, cb?: () => void) => ({
		type: TYPES.CREATE_TIME_OFF_REQUEST.REQUESTED,
		payload: data,
		callback: cb,
	});
	const updateTimeOffRequestRequested = (data: UpdateTimeOffRequestType, cb?: () => void) => ({
		type: TYPES.UPDATE_TIME_OFF_REQUEST.REQUESTED,
		payload: data,
		callback: cb,
	});
	const getTimeOffStaticsPerEmployeeRequested = ({
		employeeId,
		onlyApproved = true,
	}: {
		employeeId: number;
		onlyApproved?: boolean;
	}) => ({
		type: TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE.REQUESTED,
		payload: { employeeId, onlyApproved },
	});
	const getTimeOffStaticsPerEmployeeYearsRequested = ({
		employeeId,
		onlyApproved = true,
		years = [],
	}: {
		employeeId: number;
		onlyApproved?: boolean;
		years?: number[];
	}) => ({
		type: TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE_YEARS.REQUESTED,
		payload: { employeeId, onlyApproved, years },
	});
	const getAllTimeOffRequestsRequested = ({
		startDate,
		endDate,
	}: {
		startDate: string;
		endDate: string;
	}) => ({
		type: TYPES.GET_ALL_TIME_OFF_REQUESTS.REQUESTED,
		payload: { startDate, endDate },
	});
	const getBankHolidaysRequested = (params: { onlyCustom: boolean; expandRecurring: boolean }) => ({
		type: TYPES.GET_BANK_HOLIDAYS.REQUESTED,
		payload: params,
	});
	const approveTimeOffRequestRequested = (id: number, callback?: () => void) => ({
		type: TYPES.APPROVE_TIME_OFF_REQUEST.REQUESTED,
		payload: id,
		callback,
	});
	const approveAllTimeOffRequestsRequested = (callback?: () => void) => ({
		type: TYPES.APPROVE_ALL_TIME_OFF_REQUESTS.REQUESTED,
		callback,
	});
	const declineTimeOffRequestRequested = (
		{ requestId, comment }: { requestId: number; comment: string | null },
		callback?: () => void,
	) => ({
		type: TYPES.DECLINE_TIME_OFF_REQUEST.REQUESTED,
		payload: { requestId, comment },
		callback,
	});
	const deleteTimeOffRequestRequested = (requestId: number, callback?: () => void) => ({
		type: TYPES.DELETE_TIME_OFF_REQUEST.REQUESTED,
		payload: requestId,
		callback,
	});
	const createCustomHolidayRequested = (data: BankHolidayBaseType, callback?: () => void) => ({
		type: TYPES.CREATE_CUSTOM_HOLIDAY.REQUESTED,
		payload: data,
		callback,
	});
	const updateCustomHolidayRequested = (
		data: Omit<BankHolidayType, 'client'>,
		callback?: () => void,
	) => ({
		type: TYPES.UPDATE_CUSTOM_HOLIDAY.REQUESTED,
		payload: data,
		callback,
	});
	const deleteCustomHolidayRequested = (id: number, callback?: () => void) => ({
		type: TYPES.DELETE_CUSTOM_HOLIDAY.REQUESTED,
		payload: id,
		callback,
	});
	const getTimeOffRequestsPendingRequested = () => ({
		type: TYPES.GET_TIME_OFF_REQUESTS_PENDING.REQUESTED,
	});
	const getTimeOffRequestsForEmployeeByIdRequested = (employeeId: number) => ({
		type: TYPES.GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE_BY_ID.REQUESTED,
		payload: employeeId,
	});
	const getTimeOffStaticsCompanyRequested = (onlyApproved: boolean) => ({
		type: TYPES.GET_TIME_OFF_STATICS_COMPANY.REQUESTED,
		payload: onlyApproved,
	});

	return {
		getEmployeeStatusesRequested,
		createEmployeeRequested,
		geEmployeesRequested,
		archiveEmployeeRequested,
		unArchiveEmployeeRequested,
		changeEmployeeStatusRequested,
		getEmployeeByIdRequested,
		sendFirstDayInvitationRequested,
		getEmployeeAttachmentsRequested,
		saveEmployeeAttachmentsRequested,
		resetEmployeeDetails,
		getOwnProfileRequested,
		editOwnProfileRequested,
		editOwnPasswordRequested,
		editEmployeeRequested,
		editEmployeePasswordRequested,
		getEmployeeShortListRequested,
		getOnOffBoardingSettingsRequested,
		setOnOffBoardingSettingsRequested,
		getHRVacationSettingsRequested,
		createUpdateVacationSettingsRequested,
		deleteVacationSettingsRequested,
		getEmployeeOptionsRequested,
		getWorkWeekSettingsRequested,
		updateWorkWeekSettingsRequested,
		getTimeOffRequestDurationRequested,
		resetTimeOffRequestDuration,
		createTimeOffRequestRequested,
		updateTimeOffRequestRequested,
		getTimeOffStaticsPerEmployeeRequested,
		getTimeOffStaticsPerEmployeeYearsRequested,
		getAllTimeOffRequestsRequested,
		getBankHolidaysRequested,
		approveTimeOffRequestRequested,
		approveAllTimeOffRequestsRequested,
		declineTimeOffRequestRequested,
		deleteTimeOffRequestRequested,
		createCustomHolidayRequested,
		updateCustomHolidayRequested,
		deleteCustomHolidayRequested,
		getTimeOffRequestsPendingRequested,
		getTimeOffRequestsForEmployeeByIdRequested,
		getTimeOffStaticsCompanyRequested,
	};
};
