import { MenuProps } from 'antd';

import { COLORS } from 'theme';

import { CandidatesStatusValuesEnum } from './CandidatesApplication.types';

export const DEFAULT_TABLE_ITEMS = 5;

export const CANDIDATE_FIELDS = [
	'id',
	'name',
	'rank',
	'videoQuestionnaireInfo',
	'candidateState',
	'job',
	'isNew',
	'candidateAttachments',
];

export const statusOptions = [
	{ id: 1, name: 'New Candidate', value: CandidatesStatusValuesEnum.NewCandidate },
	{ id: 2, name: 'Potential', value: CandidatesStatusValuesEnum.Potential },
	{ id: 3, name: 'Interviews', value: CandidatesStatusValuesEnum.Interviews },
	{ id: 4, name: 'Offers', value: CandidatesStatusValuesEnum.Offers },
	{ id: 5, name: 'Hired', value: CandidatesStatusValuesEnum.Hired },
	{ id: 6, name: 'Rejected', value: CandidatesStatusValuesEnum.Rejected },
];

export const rankingOptions = [
	{ label: 'None', value: 0 },
	{ label: '1 star', value: 1 },
	{ label: '2 stars', value: 2 },
	{ label: '3 stars', value: 3 },
	{ label: '4 stars', value: 4 },
	{ label: '5 stars', value: 5 },
];

export const menuItems: MenuProps[] = (
	onOpenModalBulkAction: (id: number) => void,
	onCandidateStatus: (status: CandidatesStatusValuesEnum) => void,
	onDownloadCandidates: (e: MouseEvent) => void,
	handleSendSMSBulkAction: (ids: number[]) => void,
	handleSendEmailBulkAction: (ids: number[]) => void,
) => [
	{
		key: '1',
		icon: null,
		label: 'Action',
		className: 'action-menu',
		children: [
			{
				key: '11',
				children: null,
				label: 'Send Questionnaires',
				onClick: onOpenModalBulkAction,
			},
			{
				key: '12',
				children: null,
				label: 'Send Email',
				onClick: handleSendEmailBulkAction,
			},
			{
				key: '13',
				children: null,
				label: 'Send SMS',
				onClick: handleSendSMSBulkAction,
			},
			{
				key: '14',
				label: 'Change Status',
				className: 'sub-action-menu',
				children: [
					{
						key: '141',
						icon: null,
						children: null,
						label: 'New Candidate',
						onClick: () => onCandidateStatus(CandidatesStatusValuesEnum.NewCandidate),
					},
					{
						key: '142',
						icon: null,
						children: null,
						label: 'Potential',
						onClick: () => onCandidateStatus(CandidatesStatusValuesEnum.Potential),
					},
					{
						key: '143',
						icon: null,
						children: null,
						label: 'Interview',
						onClick: () => onCandidateStatus(CandidatesStatusValuesEnum.Interviews),
					},
					{
						key: '144',
						icon: null,
						children: null,
						label: 'Offer',
						onClick: () => onCandidateStatus(CandidatesStatusValuesEnum.Offers),
					},
					{
						key: '145',
						icon: null,
						children: null,
						label: 'Hired',
						onClick: () => onCandidateStatus(CandidatesStatusValuesEnum.Hired),
					},
					{
						key: '146',
						icon: null,
						children: null,
						label: <span style={{ color: COLORS.red }}>Reject</span>,
						onClick: () => onCandidateStatus(CandidatesStatusValuesEnum.Rejected),
					},
				],
			},
			{
				key: '15',
				icon: null,
				children: null,
				label: 'Download',
				onClick: (e) => onDownloadCandidates(e),
			},
			{
				key: '16',
				icon: null,
				children: null,
				label: <span style={{ color: COLORS.red }}>Reject</span>,
				onClick: () => onCandidateStatus(CandidatesStatusValuesEnum.Rejected),
			},
		],
	},
];
