import { createAsyncConstantsMap, createSyncConstantsMap } from 'utils/reduxHelpers';

export const createActionsTypes = (PREFIX: string) => {
	const asyncActions = createAsyncConstantsMap(PREFIX, [
		'GET_EMPLOYEE_STATUSES',
		'CREATE_EMPLOYEE',
		'VIEW_EMPLOYEES_LIST',
		'ARCHIVE_EMPLOYEE',
		'UNARCHIVE_EMPLOYEE',
		'CHANGE_EMPLOYEE_STATUS',
		'SEND_FIRST_DAY_INVITATION',
		'GET_EMPLOYEE_ATTACHMENTS',
		'SAVE_EMPLOYEE_ATTACHMENTS',
		'GET_EMPLOYEE_BY_ID',
		'EDIT_EMPLOYEE',
		'EDIT_EMPLOYEE_PASSWORD',
		'GET_OWN_PROFILE',
		'EDIT_OWN_PROFILE',
		'EDIT_OWN_PASSWORD',
		'GET_EMPLOYEE_SHORT_LIST',
		'GET_ONOFF_BOARDING_SETTINGS',
		'SET_ONOFF_BOARDING_SETTINGS',
		'GET_HR_VACATION_SETTINGS',
		'CREATE_UPDATE_VACATION_SETTINGS',
		'DELETE_VACATION_SETTINGS',
		'GET_EMPLOYEE_OPTIONS',
		'GET_WORK_WEEK_SETTINGS',
		'UPDATE_WORK_WEEK_SETTINGS',
		'GET_TIME_OFF_REQUEST_DURATION',
		'CREATE_TIME_OFF_REQUEST',
		'UPDATE_TIME_OFF_REQUEST',
		'GET_TIME_OFF_STATICS_PER_EMPLOYEE',
		'GET_TIME_OFF_STATICS_PER_EMPLOYEE_YEARS',
		'GET_ALL_TIME_OFF_REQUESTS',
		'GET_BANK_HOLIDAYS',
		'APPROVE_TIME_OFF_REQUEST',
		'APPROVE_ALL_TIME_OFF_REQUESTS',
		'DECLINE_TIME_OFF_REQUEST',
		'DELETE_TIME_OFF_REQUEST',
		'CREATE_CUSTOM_HOLIDAY',
		'UPDATE_CUSTOM_HOLIDAY',
		'DELETE_CUSTOM_HOLIDAY',
		'GET_TIME_OFF_REQUESTS_PENDING',
		'GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE_BY_ID',
		'GET_TIME_OFF_STATICS_COMPANY',
	]);

	const syncActions = createSyncConstantsMap(PREFIX, [
		'RESET_EMPLOYEE_DETAILS',
		'RESET_TIME_OFF_REQUEST_DURATION',
	]);

	return {
		...asyncActions,
		...syncActions,
	};
};
