import React, { type FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { UploadFile } from 'antd';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { pick, difference } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { useMount, useUnmount } from 'hooks';
import { acceptedImageFileFormat } from 'modules/Common/constants';
import { IEmployeeValues } from 'modules/Common/types/employee';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { employeeFormAllowedFields } from 'modules/HR/constants/HRModuleConstants.constants';
import { hrDucks } from 'modules/HR/ducks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesEnum, UserRoleNamesEnum, CookiesType } from 'types';
import { getCountryDateFormate, trimSpacesFormValues } from 'utils/helpers';

import { Routes } from '../../routes/types';

import AddressDetails from './AddressDetails';
import Attachments from './Attachments';
import { AllowedNonSUProfileFields, AllowedSUProfileFields } from './CreateEditEmployee.constants';
import {
	normalizeEmployeeStateData,
	prepareEmployeeValues,
	prepareEmployeeAttachments,
	dynamicFormValidation,
} from './CreateEditEmployee.helpers';
import { Styled } from './CreateEditEmployee.styled';
import { CreateEditEmployeeProps } from './CreateEditEmployee.types';
import EmergencyContacts from './EmergencyContacts';
import JobDetails from './JobDetails';
import OtherDetails from './OtherDetails';
import Password from './Password';
import PersonalDetails from './PersonalDetails';

const baseUrl = process.env.REACT_APP_API_URL;

export const ATS_ROLES = [
	{
		id: UserRolesEnum.JAT_SUPER_USER,
		name: UserRoleNamesEnum.JAT_SUPER_USER,
		value: 'ATS Super User',
	},
	{
		id: UserRolesEnum.JAT_FINANCE_USER,
		name: UserRoleNamesEnum.JAT_FINANCE_USER,
		value: 'ATS Finance User',
	},
	{
		id: UserRolesEnum.JAT_USER,
		name: UserRoleNamesEnum.JAT_USER,
		value: 'ATS Standard User',
	},
];

const CreateEditEmployee: FC<CreateEditEmployeeProps> = ({
	user,
	countries,
	isEditMode = false,
	employeeId,
	loading,
	resetEmployeeDetails,
	createEmployeeRequested,
	getEmployeeByIdRequested,
	getOwnProfileRequested,
	employeeDetails,
	isOwnProfile,
	editEmployeeRequested,
	editOwnProfileRequested,
}) => {
	const [photoUuid, setPhotoUuid] = useState<string | null>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [newFiles, setNewFiles] = useState<Set<string>>(new Set());

	const currentEditEmployeeFields = isEditMode && employeeDetails;
	const loggedUserIsHrManager = user?.roleId === UserRolesEnum.HR_MANAGER;
	const loggedUserIsAtsAdmin = user?.roleId === UserRolesEnum.JAT_SUPER_USER;
	const dateFormat = getCountryDateFormate(countries.isUK);

	const isAtsUserEditing =
		isEditMode && ATS_ROLES.map((u) => u.id).includes(currentEditEmployeeFields?.role);

	const employeeRolesList = [
		{
			id: UserRolesEnum.HR_EMPLOYEE,
			name: UserRoleNamesEnum.HR_EMPLOYEE,
			value: 'Employee',
		},
		{
			id: UserRolesEnum.HR_MANAGER,
			name: UserRoleNamesEnum.HR_MANAGER,
			value: 'HR Manager',
		},
	];

	if (isEditMode && (isAtsUserEditing || loggedUserIsAtsAdmin)) {
		employeeRolesList.push(...ATS_ROLES);
	}

	const navigate = useNavigate();
	const { state } = useLocation();

	const initialValues = useMemo(
		() => ({
			personalPhone: null,
			workPhone: null,
			emergencyContactPhone: null,
			usesDefaultOnboardingTemplate: true,
			...normalizeEmployeeStateData(state, isEditMode),
			...(isEditMode &&
				currentEditEmployeeFields &&
				prepareEmployeeValues(currentEditEmployeeFields)),
		}),
		[employeeDetails, state, isEditMode, currentEditEmployeeFields],
	);

	useEffect(() => {
		if (!isEditMode) return;
		const initialFileList = employeeDetails?.attachments
			? prepareEmployeeAttachments(employeeDetails?.attachments)
			: [];
		setFileList(initialFileList);
	}, [employeeDetails]);

	const onSubmit = (values: IEmployeeValues) => {
		const attachments = fileList
			?.filter((file) => file.status === 'done')
			?.map((file) => file?.response?.data);
		const valuesToSend: IEmployeeValues = trimSpacesFormValues({
			...pick(values, employeeFormAllowedFields),
			photo: photoUuid,
			...(values?.startDate && {
				startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
			}),
			...(values?.birthdayDate && {
				birthdayDate: dayjs(values.birthdayDate).format('YYYY-MM-DD'),
			}),
			...(values?.leaveDate && {
				leaveDate: dayjs(values.leaveDate).format('YYYY-MM-DD'),
			}),
			attachments,
		});

		const path = `${Routes.HRModule}${Routes.PeopleManagement}`;

		if (isEditMode) {
			if (isOwnProfile) {
				const isSuperUser =
					currentEditEmployeeFields?.role === UserRolesEnum.HR_MANAGER ||
					currentEditEmployeeFields?.role === UserRolesEnum.JAT_SUPER_USER;
				const allowedFields = isSuperUser ? AllowedSUProfileFields : AllowedNonSUProfileFields;

				editOwnProfileRequested(isSuperUser, pick(valuesToSend, allowedFields), (resp) => {
					const { newAuthJwt } = resp;
					if (newAuthJwt) {
						Cookies.set(CookiesType.JWT, newAuthJwt);
					}
					setNewFiles(new Set());
				});
			} else {
				editEmployeeRequested(true, values?.employeeId, valuesToSend, () => navigate(path));
			}
		} else {
			createEmployeeRequested(valuesToSend, () => navigate(path));
		}
	};

	useEffect(() => {
		if (currentEditEmployeeFields?.photo) {
			setPhotoUuid(currentEditEmployeeFields?.photo);
		}
	}, [currentEditEmployeeFields]);

	const handleCancel = (event: FormEvent, reset: () => void) => {
		event.preventDefault();

		reset();
	};

	const handleSetProfilePhoto = (photoId: string) => setPhotoUuid(photoId);

	const handleDeletePhoto = () => setPhotoUuid('');

	const photoProfileUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setPhotoUuid(responseData);
		},
		{
			showUploadList: false,
			accept: acceptedImageFileFormat,
			url: `${baseUrl}/hr/employee/photo`,
		},
	);

	const handleUpdateAttachmentsList = (list: UploadFile[]) => {
		setFileList(list);
	};

	const handleUpdateForNewFiles = (list: Set<string>) => {
		setNewFiles(list);
	};

	const getIsATSEmployee = (roleId: number): boolean =>
		[UserRolesEnum.JAT_SUPER_USER, UserRolesEnum.JAT_FINANCE_USER, UserRolesEnum.JAT_USER].includes(
			roleId,
		);

	const disabledFields =
		isOwnProfile &&
		[UserRolesEnum.HR_EMPLOYEE, UserRolesEnum.JAT_FINANCE_USER, UserRolesEnum.JAT_USER].includes(
			currentEditEmployeeFields?.role,
		)
			? difference(AllowedSUProfileFields, AllowedNonSUProfileFields)
			: [];

	useMount(() => {
		if (isOwnProfile) {
			getOwnProfileRequested();
		}
		if (isEditMode && employeeId) {
			getEmployeeByIdRequested(employeeId, true);
		}
	});

	useUnmount(() => {
		resetEmployeeDetails();
	});

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				validate={(values) => dynamicFormValidation(values, disabledFields)}
				render={({ handleSubmit, dirty, form }) => (
					<form onSubmit={handleSubmit}>
						<Styled.FormFieldsWrap>
							<PersonalDetails
								isEditMode={isEditMode}
								isOwnProfile={isOwnProfile}
								photo={photoUuid}
								rolesList={employeeRolesList}
								handleSetProfilePhoto={handleSetProfilePhoto}
								handleDeletePhoto={handleDeletePhoto}
								photoProfileUploaderProps={photoProfileUploaderProps}
								disabledFields={disabledFields}
								loggedUserIsHrManager={loggedUserIsHrManager}
								isAtsUserEditing={isAtsUserEditing}
								dateFormat={dateFormat}
							/>
							<AddressDetails />
							<JobDetails
								dateFormat={dateFormat}
								disabledFields={disabledFields}
								getIsATSEmployee={getIsATSEmployee}
								formObj={form}
								isEditMode={isEditMode}
								userStatus={currentEditEmployeeFields?.status}
							/>
							<EmergencyContacts />
							<Password formObj={form} />
							<Attachments
								attachmentsList={fileList}
								newAttachmentsList={newFiles}
								user={user}
								handleUpdateAttachmentsList={handleUpdateAttachmentsList}
								handleUpdateForNewFiles={handleUpdateForNewFiles}
							/>
							<OtherDetails disabledFields={disabledFields} />
						</Styled.FormFieldsWrap>
						<Styled.ButtonBox>
							<Button
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={
									!(
										dirty ||
										(isEditMode && fileList.length !== employeeDetails?.attachments?.length)
									)
								}
							>
								{isEditMode ? 'Save Changes' : 'Add Employee'}
							</Button>
						</Styled.ButtonBox>
					</form>
				)}
			></Form>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
		employeeDetails: hrDucks.hrSelectors.getEmployeeDetails(state),
	}),
	{
		getEmployeeByIdRequested: hrDucks.hrActions.getEmployeeByIdRequested,
		getOwnProfileRequested: hrDucks.hrActions.getOwnProfileRequested,
		createEmployeeRequested: hrDucks.hrActions.createEmployeeRequested,
		editEmployeeRequested: hrDucks.hrActions.editEmployeeRequested,
		editOwnProfileRequested: hrDucks.hrActions.editOwnProfileRequested,
		resetEmployeeDetails: hrDucks.hrActions.resetEmployeeDetails,
	},
)(CreateEditEmployee);
