import styled from 'styled-components/macro';

import Button from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const EmployeeVacationStatics = styled.div`
		display: flex;
		margin-bottom: 16px;
		justify-content: space-between;
		background-color: ${COLORS.lightBlue7};
		padding: 24px;
		border-radius: 10px;
		font-weight: bold;
	`;

	export const AllRequests = styled.div<{ isMobile: boolean }>`
		max-height: 640px;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		gap: 32px;
		border-radius: ${({ isMobile }) => (isMobile ? '0' : '10px')};
		background: ${COLORS.white};
		box-shadow: ${({ isMobile }) =>
		isMobile ? 'none' : '0px 2px 4px 0px rgba(99, 111, 122, 0.12)'};
		padding: ${({ isMobile }) => (isMobile ? '24px 0 0' : '24px')};
	`;

	export const RequestsWrapper = styled.div``;

	export const RequestsHeader = styled.div`
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const RequestTitle = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		letter-spacing: -0.22px;
		text-transform: capitalize;
	`;

	export const SeeMoreButton = styled(Button)`
		color: ${COLORS.blue};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		padding-right: 0;
		height: auto;
	`;

	export const RequestsList = styled.div`
		display: flex;
		flex-direction: column;
		gap: 16px;
	`;
}
