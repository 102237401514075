import { Link as LinkComponent } from 'react-router-dom';

import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

type DateWrapProps = {
	isCurrentDate: boolean;
	isSelected: boolean;
};

type RequestItemShortInfoProps = {
	isApproved: boolean;
	backgroundColor: string;
};

export namespace Styled {
	export const DateRangeWrapper = styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		@media ${device.tablet} {
			margin-bottom: 16px;
			transform: none;
		}

		button.css-dev-only-do-not-override-1m62vyb.ant-btn.ant-btn-sm.ant-btn-icon-only {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			border-color: ${COLORS.blue3};
		}
	`;

	export const DateRangeLabel = styled.span`
		color: ${COLORS.darkGray6};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		padding: 0 16px;
	`;

	export const DatesList = styled.div`
		display: flex;
		justify-content: space-between;
	`;

	export const DateWrap = styled.div<DateWrapProps>`
		text-align: center;
		display: flex;
		flex-direction: column;
		padding: 16px 10px;
		border-radius: 16px;
		background-color: ${(props) => (props.isSelected ? 'rgba(153, 167, 255, 0.3)' : COLORS.white)};
		transition: background-color 0.3s;
		&:hover {
			cursor: pointer;
		}

		div {
			color: ${COLORS.black};
			text-align: center;
			font-family: Inter;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 26px;
			letter-spacing: 0.3px;
			color: ${(props) => (props.isSelected ? COLORS.blue : COLORS.black)};
			transition: color 0.3s;
		}
		span {
			color: ${(props) => (props.isSelected ? COLORS.blue : '#94A3B8')};
			text-align: center;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 26px;
			letter-spacing: 0.3px;
			position: relative;
			transition: color 0.3s;
			&:after {
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				background-color: ${(props) => (props.isCurrentDate ? COLORS.blue : 'transparent')};
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 50%;
			}
		}
	`;

	export const RequestsContent = styled.div`
		margin-top: 16px;
	`;

	export const RequestsHeader = styled.h4`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.3px;
		margin-bottom: 12px;
	`;

	export const RequestsList = styled.div`
		display: flex;
		flex-direction: column;
	`;

	export const RequestItemShortInfo = styled.div<RequestItemShortInfoProps>`
		color: ${(props) => (props.isApproved ? COLORS.black : COLORS.red)};
		background-color: ${(props) => (props.isApproved ? props.backgroundColor : COLORS.white)};
		border: 1px solid ${(props) => (props.isApproved ? props.backgroundColor : COLORS.red)};
		border-radius: 6px;
		padding: 4px 8px;
		margin-bottom: 8px;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	`;

	export const NoRequests = styled.div`
		color: ${COLORS.gray};
	`;
}
